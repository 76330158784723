import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 700
  },
  tableHead: {
    fontWeight: 700
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  hidden: {
    display: "none"
  }
}));

export default useStyles;
