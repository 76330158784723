import { makeStyles } from "@mui/styles";

const drawerWidth = 340;
const background = "#f4f5f7";
export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(3),
    minHeight: `calc(100vh - ${theme.spacing(3)})`,
    // marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    backgroundColor: background
  },
  rootShift: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(3),
    minHeight: `calc(100vh - ${theme.spacing(3)})`,
    marginLeft: drawerWidth,
    // marginRight: theme.spacing(1),
    backgroundColor: background
  },
  rootLogin: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundColor: background
  },
  main: {
    //marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2)
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "18px 20px 20px 20px",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
    //marginLeft: -drawerWidth+30,
  }
}));
