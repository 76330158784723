import React from "react";
import { Link as RouterLink } from "react-router-dom";
import useStyles from "./style";

import { ListItem, ListItemIcon, ListItemText, ListSubheader, Menu, MenuItem } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const ItemWithDropdown = ({ item }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <ListItem className="menu-list-item" button onClick={handleClick}>
        <ListItemIcon className={classes.menuListItemIcon}>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
        <ChevronRightIcon />
      </ListItem>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
        open={open}
        onClose={handleClose}
      >
        {item?.items?.map(listItem => {
          const id = "mi-" + listItem.link;

          return (
            <MenuItem
              key={id}
              sx={{ px: 4, justifyContent: "center" }}
              className={classes.menuDropdownItem}
              onClick={handleClose}
              component={RouterLink}
              to={listItem.link}
            >
              {listItem.title}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export function useMenuItems(navigation) {
  const classes = useStyles();

  const menuItems = [];
  navigation.forEach(navigationGroup => {
    menuItems.push(<ListSubheader key={navigationGroup.title}>{navigationGroup.title}</ListSubheader>);

    navigationGroup.items.forEach(item => {
      const id = "mi-" + item.link;

      if (item.items) {
        menuItems.push(<ItemWithDropdown key={id} item={item} />);
      } else {
        menuItems.push(
          <ListItem key={id} button component={RouterLink} to={item.link}>
            <ListItemIcon className={classes.menuListItemIcon}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        );
      }
    });
  });

  return <div className={classes.menuItems}>{menuItems}</div>;
}
