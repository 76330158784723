import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { DataView } from "Components/General/DataView/DataView";
import { useForm } from "react-hook-form";
import FormDatePicker from "Components/General/FormTypes/FormDatePicker";
import SendIcon from '@mui/icons-material/Send';
import {Clear as ClearIcon } from "@mui/icons-material";

// import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { SendAlert } from "./SendAlert";
import { BlockAccount } from "./BlockAccount";
import { getUnfinishedTransactions } from "Services/api/unfinishedTransactions/unfinishedTransactions";
import { Button, Paper, Grid, Stack, Typography } from "@mui/material";
import CachedIcon from '@mui/icons-material/Cached';

const UnfinishedTransactions = () => {

  const [period, setPeriod] = React.useState({ dateFrom: new Date(), dateTo: new Date() });
  const [dataRefresh, setDataRefresh] = React.useState(null);
  const form = useForm();

  const handleSubmit = async data => {    
    setPeriod({dateFrom: new Date(data.dateFrom), dateTo: new Date(data.dateTo)})
    setDataRefresh({});
  };

  return (
    <div style={{
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      height: "100%",
      width: "100%"
    }}>

    <Grid item xs={6} marginTop="10px" marginBottom="10px">
        <Stack spacing={1} direction="row" justifyContent="flex-start">
          <Typography component="h1" variant="h5">
            LISTA NIESFINALIZOWANYCH TRANSAKCJI
          </Typography>
        </Stack>
    </Grid>

    <Paper sx={{ borderRadius: 4, padding: 5, width: "fit-content" }}>

        <Stack direction="row" spacing={2}>
          <FormDatePicker 
            control={form.control} 
            name={"dateFrom"} 
            label={"Data od"} 
            required={true}
            rules={{ required: "Pole obowiązkowe" }}
            value={period.dateFrom}
          />
          <FormDatePicker
            control={form.control}
            name={"dateTo"}
            label={"Data do"}
            required={true}
            rules={{ required: "Pole obowiązkowe" }}
            value={period.dateTo}
          /> 
          
          <Button sx={{ textTransform: "none", fontSize: 22}}
              startIcon={<CachedIcon sx={{ fontSize: 31 }} />}
              color="primary"
              variant="contained"
              onClick={form.handleSubmit(handleSubmit)}
            >
              Odśwież dane
          </Button>
        </Stack>
    </Paper>

    <DataView
     sx={{ flexGrow: 1, p: 3}}
      columns={[
        { field: "id", hide: true, filterable: false },
        {
          field: "userEmail",
          headerName: "Nazwa użytkownika",
          flex: 2,
          filterable: false,
          sortable: false
        },
        { field: "transactionsCount", headerName: "Ilość", flex: 1, filterable: false, sortable: false },
 
        {
          field: "lastNotificationDate",
          headerName: "Data ostatnio wysłanego alertu",
          flex: 2,
          type: "dateTime",
          valueFormatter: params =>
            params.value
              ? `${moment(params.value).format("DD.MM.YYYY")} ${moment(params.value).format("HH:mm:ss")}`
              : "-",
          filterable: false, sortable: false
        }
      ]}      
      actions={[        
        {
          flex: 1,
          type: "row",
          label: "Zleć wysłanie alertu",
          title: "Zleć wysłanie alertu",
          permission: "UnfinishedTransactions/SendAlert",
          icon: <SendIcon className="GridEditIcon" />,          
          onClick: params => <SendAlert {...params} />
        },
        {
          flex: 1,
          type: "row",
          label: "Zablokuj konto",
          title: "Zablokuj konto",
          permission: "UnfinishedTransactions/BlockUser",
          icon: <ClearIcon className="GridDeleteIcon" />,          
          onClick: params => <BlockAccount {...params} />
        }       
      ]}
      loadData={(filter, sort, limit) => getUnfinishedTransactions(filter, sort, limit, period)}
      dataRefresh={dataRefresh}
    ></DataView>

  </div>
  );
};

export default withRouter(UnfinishedTransactions);
