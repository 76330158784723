import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import { MessagePopup } from "Components/General/Popup/MessagePopup";
import { getTicketDetails } from "Services/api/records/records";
import { getDiscounts } from "Services/api/dictionary/dictionary";
import { DataCard } from "./components/DataCard";
import { UserData } from "./components/UserData";
import { TransactionData } from "./components/TransactionData";
import { TicketData } from "./components/TicketData";
import { StatusData } from "./components/StatusData";
import { Remarks } from "./components/Remarks";

export const TicketDetails = ({ popup, onSubmit, item }) => {
  const [details, setDetails] = useState();
  const [discounts, setDiscounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    const fetchTicketDetails = async () => {
      const data = await getTicketDetails(item.ticketId);
      setDetails(data);
    };
    const fetchDiscounts = async () => {
      const data = await getDiscounts();
      setDiscounts(data);  
    };

    fetchTicketDetails();
    fetchDiscounts();
    setIsLoading(false);
    
  }, []);

  return (
    <MessagePopup popup={popup} onSubmit={onSubmit} fullWidth>
      {isLoading || !details ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid justifyContent="center" container padding={2} spacing={4}>
          <Grid item xs={details.cardOwner ? 6 : 12}>
            <UserData title="Dane kupującego" data={details.buyer} discounts={discounts} />
          </Grid>
          {details.cardOwner && (
            <Grid item xs={6}>
              <UserData title="Dane właściciela karty" data={details.cardOwner} discounts={discounts} />
            </Grid>
          )}
          <Grid item xs={12}>
            <DataCard title="Transakcje">
              <TransactionData title="Identyfikacja transakcji sprzedaży" data={details.purchaseTransaction} />
              {details.refundTransaction && (
                <TransactionData title="Identyfikacja transakcji zwrotu" data={details.refundTransaction} noDivider />
              )}
            </DataCard>
          </Grid>
          <Grid item xs={6}>
            <TicketData ticket={details.ticket} />
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={4}>
              <StatusData ticket={details.ticket} prohibitInvoicing={details.prohibitInvoicing} invoiced={details.invoiced} invoiceData={details.invoiceData} ticketId={item.ticketId} />
              <Remarks ticketId={item.ticketId} items={details.remarks}/>
            </Stack>
          </Grid>
        </Grid>
      )}
    </MessagePopup>
  );
};
