import React from "react";

import { QuestionPopup } from "Components/General/Popup/QuestionPopup";
import { deleteRole as deleteRoleApi } from "Services/api/roles/roles";

export const DeleteRole = ({ popup, action, item }) => {
  const onSubmit = async data => {
    deleteRoleApi(item.id);
  };

  return (
    <QuestionPopup popup={popup} onSubmit={onSubmit}>
      Czy na pewno chcesz usunąć rolę "{item.name}"?
    </QuestionPopup>
  );
};
