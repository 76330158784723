import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";

import { getGridNumericColumnOperators, getGridDateOperators } from '@mui/x-data-grid-pro';
import { DataView } from "Components/General/DataView/DataView";
import { getRegisters } from "Services/api/registers/registers";

const Registers = () => {
  return (
    <DataView
      style={{ maxWidth: "900px" }}
      title="LISTA STATUSÓW REJESTRACJI"
      columns={[
        { field: "id", hide: true, filterable: false },
        {
          field: "daydate",
          headerName: "Data",
          flex: 1,
          type: "dateTime",
          valueFormatter: params => `${moment(params.value).format("DD.MM.YYYY")}`,
          filterOperators: getGridDateOperators(true).filter(
            operator => operator.value === "onOrAfter" || operator.value === "before"
          )
        },
        {
          field: "accountscount",
          headerName: "Nowe konta",
          flex: 2,
          type: "number",
          filterOperators: getGridNumericColumnOperators().filter(
            operator => operator.value === "=" || operator.value === "!=" || operator.value === ">" || operator.value === ">=" || operator.value === "<" || operator.value === "<="
          )
        },
        {
          field: "subaccountscount", 
          headerName: "Nowe subkonta", 
          flex: 2, 
          type: "number", 
          filterOperators: getGridNumericColumnOperators().filter(
            operator => operator.value === "=" || operator.value === "!=" || operator.value === ">" || operator.value === ">=" || operator.value === "<" || operator.value === "<="
          )
        }
      ]}
      actions={[]}
      loadData={(filter, sort, limit) => getRegisters(filter, sort, limit)}
      initialSort={[{ field: "daydate", sort: "desc" }]}
      mapStatus
    ></DataView>
  );
};

export default withRouter(Registers);
