import { axiosClient } from "Services/api/axios";

export async function getTutorials(filters, sort, limit) {
    let [resData] = [undefined];

    await axiosClient({
        method: "GET",
        url: "/cms/tutorials"
    })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

    return resData;
}

export async function getTutorialWithTranslations(id) {
    let [resData] = [undefined];

    await axiosClient({
        method: "GET",
        url: "/cms/tutorials/" + id
    })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

    return resData;
}


export async function saveTutorial(data) {

    let [result] = [true];

    await axiosClient({
        method: "POST",
        url: "/cms/tutorials",
        data: data
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            result = false;
        });

    return result;
}

export async function updateTutorial(data) {
    let [result] = [true];
    await axiosClient({
        method: "PUT",
        url: "/cms/tutorials/" + data.id,
        data: data
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            result = false;
        });
    return result;

}


export async function deleteTutorial(id) {
    await axiosClient({
        method: "DELETE",
        url: "/cms/tutorials/" + id,
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}

export async function setTutorialOrder(data) {
    let [result] = [true];
    await axiosClient({
        method: "PATCH",
        url: "/cms/tutorials/" + data.id + "/set-order",
        params: { up: data.up }
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            result = false;
        });
    return result;

}
