import React from "react";
import { withRouter } from "react-router-dom";

import { DataView } from "Components/General/DataView/DataView";
import { Edit as EditIcon, Add as AddIcon, Clear as ClearIcon } from "@mui/icons-material";

import { AddEditTutorial } from "./AddEditTutorial";
import { DeleteTutorial } from "./DeleteTutorial";

import { getTutorials, setTutorialOrder } from "Services/api/cms/tutorials";

const Contact = () => {
  // const otherAction = (action, item) => {};

  return (
    <DataView
      title="SAMOUCZEK"
      columns={[
        { field: "id", hide: true },
        { field: "title", headerName: "Tytuł", flex: 1.5, sortable: false, filterable: false },
        {
          field: "isPublic",
          headerName: "Czy aktywny",
          flex: 1,
          sortable: false,
          filterable: false,
          renderCell: params => (params.value ? "tak" : "nie")
        }
      ]}
      actions={[
        {
          type: "row",
          permission: "Cms/Tutorial/Edit",
          label: "Edytuj",
          title: "Edytuj samouczek",
          icon: <EditIcon className="GridEditIcon" />,
          onClick: params => <AddEditTutorial {...params} />
        },
        {
          type: "row",
          permission: "Cms/Tutorial/Delete",
          label: "Usuń",
          title: "Usuń samouczek",
          icon: <ClearIcon className="GridDeleteIcon" />,
          onClick: params => <DeleteTutorial {...params} />
        },
        {
          type: "grid",
          permission: "Cms/Tutorial/Add",
          label: "Dodaj samouczek",
          title: "Dodaj samouczek",
          icon: <AddIcon className="AddIcon" />,
          onClick: params => <AddEditTutorial {...params} />
        }
      ]}
      loadData={(filters, sort, limit) => getTutorials(filters, sort, limit)}
      withOrderColumn
      changeOrder={setTutorialOrder}
      hideFooter
    ></DataView>
  );
};

export default withRouter(Contact);
