import React, { useEffect, useState } from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Chip } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";

import { getGridDateOperators } from "@mui/x-data-grid-pro";
import { getGridNumericColumnOperators } from "@mui/x-data-grid-pro";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
import { DataView } from "Components/General/DataView/DataView";
import { getRecords } from "Services/api/records/records";
import {
  getTicketStatusColor,
  getPaymentStatusColor,
  getPaymentStatusText,
  getTicketStatusText
} from "Common/utils/statusesUtils";
import { formatPrice } from "Common/utils/priceUtils";
import { TicketDetails } from "./TicketDetails";
import { PaymentDetails } from "./PaymentDetails";
import useUserContext from "Context/useUserContext";

const Records = () => {
  const { userContext } = useUserContext();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (JSON.stringify(userContext.user.permissions).match(/Sale\/Details/i)) {
      setShowDetails(true);
    }
  }, []);

  let filtersArray = [];
  let dataGridActions = [];

  const queryParams = new URLSearchParams(window.location.search);

  let i = 1;
  let filterColumn = "";
  let filterOperator = "";
  let filterValue = "";

  for (const [key, value] of queryParams) {
    switch (key) {
      case "status":
        filterColumn = "status";
        filterOperator = "is";
        filterValue = value;
        break;
      default:
        filterColumn = "";
        filterOperator = "";
        filterValue = "";
        break;
    }

    if (filterColumn !== "") {
      filtersArray.push({ id: i, columnField: filterColumn, operatorValue: filterOperator, value: filterValue });
    }

    i++;
  }

  let filters = { items: filtersArray };

  if (showDetails) {
    dataGridActions.push(
      {
        flex: 1.3,
        type: "row",
        label: "Szczegóły biletu",
        title: "Szczegóły biletu",
        permission: "Sale",
        icon: <ChevronRight className="GridEditIcon" />,
        onClick: params => <TicketDetails {...params} />
      },
      {
        flex: 1.3,
        type: "row",
        label: "Szczegóły płatności",
        title: "Szczegóły płatności",
        permission: "Sale",
        icon: <ChevronRight className="GridEditIcon" />,
        onClick: params => <PaymentDetails {...params} />
      }
    );
  }

  return (
    <DataView
      title="ZESTAWIENIE SPRZEDAŻY"
      columns={[
        { field: "id", hide: true, filterable: false },
        {
          field: "transactionId",
          headerName: "ID transakcji",
          flex: 1,
          type: "number",
          filterOperators: getGridNumericColumnOperators().filter(
            operator =>
              operator.value === "=" ||
              operator.value === "!=" ||
              operator.value === ">" ||
              operator.value === ">=" ||
              operator.value === "<" ||
              operator.value === "<="
          )
        },
        { field: "login", headerName: "Login", flex: 2.5 },
        {
          field: "cardNumber",
          headerName: "Numer karty",
          flex: 1.5,
          type: "string",
          filterOperators: getGridStringOperators().filter(
            operator => operator.value != "isEmpty" && operator.value !== "isNotEmpty"
          )
        },
        {
          field: "date",
          headerName: "Data transakcji",
          flex: 1.2,
          type: "dateTime",
          valueFormatter: params =>
            `${moment(params.value).format("DD.MM.YYYY")} ${moment(params.value).format("HH:mm:ss")}`,
          filterOperators: getGridDateOperators(true).filter(
            operator => operator.value === "onOrAfter" || operator.value === "before"
          )
        },
        {
          field: "ticketNumber",
          headerName: "Nr biletu",
          flex: 1,
          type: "string",
          filterOperators: getGridStringOperators().filter(
            operator => operator.value != "isEmpty" && operator.value !== "isNotEmpty"
          )
        },
        {
          field: "paymentStatus",
          headerName: "Status płatności",
          flex: 2,
          renderCell: params => {
            return (
              <Chip
                color={getPaymentStatusColor(params.value)}
                style={{ color: "white", width: 200 }}
                label={getPaymentStatusText(params.value)?.toUpperCase()}
              />
            );
          },
          type: "singleSelect",
          valueOptions: ["Nieopłacona", "Rozpoczęta", "W trakcie", "Zakończona", "Błąd", "Odrzucona", "Anulowana"]
        },
        {
          field: "status",
          headerName: "Status biletu",
          flex: 2,
          renderCell: params => (
            <Chip
              color={getTicketStatusColor(params.value)}
              style={{ color: "white", width: 200 }}
              label={getTicketStatusText(params.value)?.toUpperCase()}
            />
          ),
          type: "singleSelect",
          valueOptions: ["Kupiony", "Zwrócony", "Wymieniony", "Wymiana", "Deklaracja zwrotu"]
        }
      ]}
      actions={dataGridActions}
      loadData={(filter, sort, limit) =>
        getRecords(filter, sort, limit).then(response => ({
          ...response,
          result: response.result.map((record, index) => ({ ...record, id: index }))
        }))
      }
      initialSort={[{ field: "date", sort: "desc" }]}
      initialFilter={filters}
      mapStatus
    ></DataView>
  );
};

export default withRouter(Records);
