import { axiosClient } from "Services/api/axios";

export async function getNews(filters, sort, limit) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/cms/news"
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function getNewsWithTranslations(id) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/cms/news/" + id
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function saveNews(data) {
  let [result] = [true];

  await axiosClient({
    method: "POST",
    url: "/cms/news",
    data: data
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      result = false;
    });

  return result;
}

export async function updateNews(data) {
  let [result] = [true];
  await axiosClient({
    method: "PUT",
    url: "/cms/news/" + data.id,
    data: data
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      result = false;
    });
  return result;
}

export async function deleteNews(id) {
  await axiosClient({
    method: "DELETE",
    url: "/cms/news/" + id
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}
