import { TextField, Grid, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Controller } from "react-hook-form";
import { useState } from "react";

/**
 *
 * @param props.type typ pola input (valid HTML5 input type).
 * @param props.control dla walidacji
 * @param props.name - nazwa pola
 * @param props.label - opis pola
 * @param props.required - czy wymagane
 * @param props.variant - typ graficzny dostępne opcje: filled | outlined | standard
 * @param props.xs - szerokość pola w grid przy mobile
 * @param props.sm - szerokość pola w grid przy desktop
 * @param props.rules - warunki walidacji
 *
 * @returns pole tekstowe typu input
 */




const FormPasswordField = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //console.log("props", props);
  return (
    <Grid  item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.value}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            autocomplete={props.autocomplete}
            key={props.name}  
            name={props.name}
            id={props.name}
            label={props.label}
            required={props.required}
            variant={props.variant ? props.variant : "outlined"}
            fullWidth
            value={props.value}
            onChange={onChange}
            type={showPassword ? "text" : "password"}
            error={!!error}
            helperText={error ? error.message : null}
            InputProps={{        
              maxLength: props.maxLength,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="pokaż/ukryj hasło"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )            
            }}
          />
        )}
        rules={props.rules}
      />
    </Grid>
  );
};

export default FormPasswordField;
