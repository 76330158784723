import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  report: {
    position: "relative",
    // marginTop: "80px",
    width: "100%",
    height: "72vh",
    overflow: "hidden",
    }
}));

export default useStyles;

