import { Card, Divider, Typography } from "@mui/material";
import React from "react";

export const DataCard = ({ children, title }) => {
  return (
    <Card style={{ borderRadius: 15 }}>
      <Typography variant="h5" component="div" padding={2}>
        {title}
      </Typography>
      <Divider />
      {children}
    </Card>
  );
};
