import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

//import { Close as CloseIcon, Done as DoneIcon } from '@mui/icons-material';

import useStyles from "./style";


export const QuestionPopup = ({ popup, onSubmit, onCancel, children }) => {

   const classes = useStyles();

   const handleClose = async (result) => {
      if (result && onSubmit)
         await onSubmit();
      else if (!result && onCancel)
         await onCancel();

      popup.handleClose(result);
   };


   return (
      <Dialog open={true} onClose={() => handleClose(false)}>
         <DialogTitle>
            <div>
               <Typography className={classes.title}>
                  {popup.title}
               </Typography>
            </div>
         </DialogTitle>
         <DialogContent>
            {children}
         </DialogContent>
         <DialogActions className={classes.actions}>
            <Button color="primary" variant="contained" onClick={() => handleClose(true)} className={classes.submitButton}>
               Tak
            </Button>
            <Button variant="outlined" onClick={() => handleClose(false)} className={classes.cancelButton}>
               Nie
            </Button>
         </DialogActions>
      </Dialog>
   );
}
