import React from 'react';
import { Typography, Grid, Stack } from "@mui/material";
import ReportTypeField from './components/ReportTypeField';
import ReportViewer from './components/ReportViewer';
import { getReports } from "Services/api/reports/reports";

const Reports = ({
    token
}) => {

    //const [report, setReport] = React.useState({ file: "ComparativeFinancialReport.trdx" });
    const [report, setReport] = React.useState('');
    const [reports, setReports] = React.useState([]);

    React.useEffect(() => {
        (async () => setReports(await getReports()))();
    },[]);

    const onReportChange = async (r) => {
        setReport(r);
    }

    return (reports.length > 0 ? (

    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        height: "100%",
        width: "100%"
      }}
    >

        <Grid container direction="row" padding={"0 0 20px 0"}>
            <Grid item xs={3} marginTop="10px" marginBottom="10px">
                <Stack spacing={1} direction="row" justifyContent="flex-start">
                <Typography component="h1" variant="h5">
                    Raporty
                </Typography>
                </Stack>
            </Grid>
            <Grid item xs={6} padding={"10px 10px 0px 0"} container justifyContent="center">
                <ReportTypeField value={report} options={reports} onChange={onReportChange} />
            </Grid>
        </Grid>

        
        {report && <ReportViewer token={token} reportName={report.file} /> }
        
  
    </div> )
        
    : null
    )
};

export default Reports;