import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

import useStyles from "./style";
import Meta from "Components/General/Meta/index";
import { ResetPasswordInit } from "Services/api/security/security";
import FormTextField from "Components/General/FormTypes/FormTextField";

import { Avatar, Container, Button, Typography, Grid } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export const PasswordResetInit = props => {
  const classes = useStyles();
  let history = useHistory();

  const { handleSubmit, control } = useForm();

  const onSubmit = async data => {
    const result = await ResetPasswordInit(data.email);
    if (result !== undefined) {
      history.push("/");
    }
  };

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Meta subTitle={"Przypomnij hasło"}></Meta>

      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography className={classes.title} component="h1" variant="h5">
        {"Przypomnienie hasła"}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container direction="column" spacing={2}>
          <FormTextField
            control={control}
            label={"Email"}
            required={true}
            name={"email"}
            rules={{ required: "Podaj email" }}
            key={"email"}
          />
        </Grid>
        <Button id="btn-submit" type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          {"Przypomnij"}
        </Button>
      </form>
    </Container>
  );
};
