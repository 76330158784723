import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { SnackbarProvider } from "notistack";

import App from "./application/App";
import APIErrorProvider from "Common/providers/APIErrorProvider";
import APIErrorNotification from "Components/General/APIErrorNotification";

import * as theme from "Themes/index";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/material/styles";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import "moment/locale/pl";
import DateAdapter from "@mui/lab/AdapterMoment";

// >>potential<< solutions for datetimepicker time zone problems
// let MomentUtils = require('@date-io/moment');
// let moment = require('moment');
// let _ = require('moment-timezone');
// moment.tz.setDefault('Europe/Warsaw');
//<LocalizationProvider dateAdapter={DateAdapter} libInstance={moment}>

ReactDOM.render(
  <APIErrorProvider>
    <BrowserRouter>
      <>
        <SnackbarProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme.light}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <App />
                <APIErrorNotification />
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </SnackbarProvider>
      </>
    </BrowserRouter>
  </APIErrorProvider>,
  document.getElementById("root")
);
