import React from "react";
import { RedirectPopup } from "Components/General/Popup/RedirectPopup";
import useUserContext from "Context/useUserContext";

export const PasswordAlert = () => {
  const { userContext, setUserContext } = useUserContext();

  const onClosePopup = () => {
    userContext.changePassword = false;
    setUserContext(userContext);
  };

  return (
    <RedirectPopup
      title="Zmień hasło"
      description="Twoje hasło jest przestarzałe i powinno zostać zmienione. Czy chcesz teraz dokonać zmiany hasła?"
      redirect="/user-change-pwd"
      redirectButton="TAK"
      cancelButton="NIE"
      onClose={onClosePopup}
    />
  );
};
