import { axiosClient } from "Services/api/axios";

export async function getRoles(filter, sort, limit) { 
    let [resData] = [undefined]; 

    await axiosClient({
        method: "GET",
        url: "/roles",
        params: { filter: filter, sort: sort, limit: limit }
        })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

        return resData;
}

export async function getRolesLookup() {

    let [resData] = [undefined];

    await axiosClient({
        method: "GET",
        url: "/roles/lookup"        
        })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        });

    return resData;
}

export async function getRolePermissions(role = null) { 
    let [resData] = [undefined]; 
    var roleId = role ? role.id : 0;

    await axiosClient({
        method: "GET",
        url: "/roles/" + roleId + "/permissions"
        })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

        return resData;
}

export async function saveRole(name, description, permissionIds) { 
    let [resData] = [undefined]; 

    await axiosClient({
        method: "POST",
        url: "/roles",
        data: { name, description, permissionIds }
        })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

        return resData;
}

export async function updateRole(id, name, description, permissionIds) { 
    let [resData] = [undefined]; 

    await axiosClient({
        method: "PUT",
        url: "/roles/" + id,
        data: { id, name, description, permissionIds }
        })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

        return resData;
}

export async function deleteRole(id) { 
    let [resData] = [undefined]; 

    await axiosClient({
        method: "DELETE",
        url: "/roles/" + id
        })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

        return resData;
}