import useStyles from "./style";

import AccountCircle from "@mui/icons-material/AccountCircle";

const UserInfo = (props) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.info}>
        <AccountCircle className={classes.icon} />
        <div>
          <div className={classes.infoText}>Zalogowany:</div>
          <div className={classes.name}>
            {`${props.lastName} ${props.firstName}`}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfo;
