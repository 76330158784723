import useAPIError from "Common/hooks/useAPIError";
import { useSnackbar } from "notistack";

export default function APIErrorNotification() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { error, removeError } = useAPIError();

  const handleSubmit = () => {
    removeError();
    closeSnackbar();
  };

  return error
    ? enqueueSnackbar(error.message, {
        variant: "error",
        onClose: () => handleSubmit(),
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center"
        },
        preventDuplicate: true
      })
    : null;
}
