import { makeStyles } from "@mui/styles";

const drawerWidth = 340;
const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    marginBottom: 60
  },
  menuButton: {
    marginRight: theme.spacing(2),
    marginLeft: -3
  },
  menuButtonHidden: {
    display: "none"
  },
  appBar: {
    backgroundColor: theme.palette.primary.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    boxShadow: "none"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth})`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  appBarSpacer: theme.mixins.toolbar,
  menuItem: {
    height: "100%",
    minWidth: 120,
    display: "inline-block",
    backgroundColor: "green",
    padding: 15,
    marginLeft: 1,
    justifyContent: "center"
  },
  link: {
    //height: "100vh",
    color: "white",
    paddingLeft: 15,
    textTransform: "uppercase"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    // margin: theme.spacing(1, 0),
    overflowX: "hidden",
    overflowY: "hidden",
    paddingRight: 10,
    "&:hover": {
      overflowY: "auto",
      paddingRight: 0
    },
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },

    height: "100%",
    paddingTop: 80
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end"
  },
  accountButton: {
    textTransform: "none",
    color: "#f4f5f7"
  },
  languageButton: {
    marginRight: theme.spacing(2)
  },
  userInfo: {
    padding: 20,
    borderBottom: "1px solid #E0E0E0"
  },
  listItems: {
    padding: "0 20px 20px 20px",
    "& ul": {
      padding: 0
    }
  },
  toolBar: {
    padding: "14px 16px"
  },
  toolBarAccountIcon: {
    color: "inherit",
    width: 36,
    height: 36
  }
}));

export default useStyles;
