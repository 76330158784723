import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 480
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  submitButton: {
    fontSize: 22,
    fontWeight: "bold",
    textTransform: "none",
    minWidth: 140,
    minHeight: 60,
    borderRadius: 8
  },
}));

export default useStyles;
