import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  menuItems: {
    "& > a": {
      padding: "6px 0 6px 17px"
    },
    "& span": {
      fontSize: "1.125rem", //18px
      color: "#5c5a59",
      lineHeight: "1.2"
    },
    "& li": {
      color: "#222222",
      fontWeight: "bold",
      fontSize: "1.125rem", //18px
      padding: "20px 0 10px 0",
      lineHeight: "1.2"
    },
    "& svg": {
      color: "#5c5a59",
      width: 28,
      height: 28
    }
  },
  menuDropdownItem: {
    fontSize: "1.125rem", //18px
    color: "#5c5a59",
    lineHeight: "1.2"
  },
  menuListItemIcon: {
    minWidth: "auto",
    marginRight: 20
  }
}));

export default useStyles;
