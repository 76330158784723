import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  info: {
    background: "#F4F5F7",
    padding: "12px 20px 12px 12px",
    margin: 0,
    borderRadius: "15px",
    display: "flex",
    alignItems: "center"
  },

  icon: {
    marginRight: "8px",
    fontSize: "66px",
    color: theme.palette.secondary.main
  },

  name: {
    fontWeight: "bold"
  },

  infoText: {
    color: "#707070"
  }
}));

export default useStyles;
