import React from "react";

import { AppBar, Drawer, Toolbar, Button, IconButton, List } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircle from "@mui/icons-material/AccountCircle";

import useStyles from "./style";

import { useMenuItems } from "./components/menuItems";
import UserMenu from "./components/UserMenu/UserMenu";
import UserInfo from "./components/UserInfo/UserInfo";
import { LogoutUser } from "Services/api/security/security";

import companyLogo from "Assets/images/logo-km.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Grid from "@mui/material/Grid";

const Menu = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isDrawerOpen);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigation = props.navigation;

  const handleDrawerOpen = () => {
    props.onToolbarClick(open);
    setOpen(!open);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const onSignOut = () => {
    LogoutUser();
    props.clearUserContext();
  };

  const mainListItems = useMenuItems(navigation);

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Grid container spacing={0}>
            <Grid item xs={5} md={6}>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={handleDrawerOpen}
              >
                <MenuIcon />
              </IconButton>
              <img src={companyLogo} alt="Logo" />
            </Grid>
            <Grid item container xs={7} md={6} direction="row" justifyContent="flex-end" alignItems="center">
              <>
                <Button
                  className={classes.accountButton}
                  color="inherit"
                  onClick={async event => {
                    setAnchorEl(event.currentTarget);
                  }}
                  startIcon={<AccountCircle className={classes.toolBarAccountIcon} />}
                >
                  {props.user.lastName + " " + props.user.firstName}
                  <ArrowDropDownIcon />
                </Button>
                <UserMenu topAnchor={anchorEl} handleClose={() => setAnchorEl(null)} signOut={onSignOut} />
              </>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        {/* <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose} value="Wróć">
            <ChevronLeftIcon />
          </IconButton>
        </div> */}
        <div className={classes.userInfo}>
          <UserInfo firstName={props.user.firstName} lastName={props.user.lastName} />
        </div>
        <div className={classes.listItems}>
          <List>{mainListItems}</List>
        </div>
      </Drawer>
    </div>
  );
};

export default Menu;
