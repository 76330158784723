import { Chip, Button, ToggleButton} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import Stack from '@mui/material/Stack';
import React from "react";
import { DataCard } from "./DataCard";
import { DataRow } from "./DataRow";
import { getTicketStatusColor, getTicketStatusText } from "Common/utils/statusesUtils";
import { DisabledCheckbox } from "./DisabledCheckbox";
import { downloadReturnForm } from "Services/api/records/records";
import { setProhibitInvoicing } from "Services/api/records/records";
import { downloadFile } from "Common/utils/filesUtils";

export const StatusData = ({ ticket, prohibitInvoicing, invoiced, invoiceData, ticketId }) => {

  const [checked, setChecked] = React.useState(prohibitInvoicing);

  const handleChange = () => {
    
    setProhibitInvoicing(ticketId, !checked);
    setChecked(!checked);
  };

  return (
    <DataCard title="Status biletu">
      <DataRow
        title="Status"
        customContent={
          <Stack direction="row" spacing={2} justifyContent="center"
          alignItems="center">
          <Chip
            color={getTicketStatusColor(ticket?.status)}
            style={{ color: "white" }}
            label={getTicketStatusText(ticket?.status)?.toUpperCase()}
          />
          {(ticket?.status === 2 || ticket?.status === 3) && ticket?.returnForm === true ?
           <Button
              id="btn-submit"
              type="submit"
              
              variant="contained"
              startIcon={<DownloadIcon className="GridEditIcon" />}
              color="primary"    
              onClick={() => {                
                downloadReturnForm(ticketId).then(downloadFile);
              }}          
              >
                Formularz zwrotu
               
      </Button> 
      :
      null
      }
          </Stack>
        }
      />
      <DataRow title="Utracony" customContent={<DisabledCheckbox checked={ticket?.lost} />} />
      <DataRow title="Procesowany" customContent={<DisabledCheckbox checked={ticket?.processed} />} />
      <DataRow title="Pobrany na telefon" customContent={<DisabledCheckbox checked={ticket?.printed} />} />
      <DataRow title="Faktura" customContent={
        <Stack direction="row"><DisabledCheckbox checked={invoiced} />
            {!invoiced && <ToggleButton color="error" value="check" sx={{height: "26px", m: "2px 14px"}} selected={checked} onChange={handleChange}> 
              { checked? "Odblokuj wystawianie faktury": "Zablokuj wystawianie faktury"}
            </ToggleButton>}
        </Stack>} 
      />
      {/* // Brak danych do faktury w api
      {invoiced && (
        <DataRow
          title="Dane firmy"
          customContent={
            <>
              <Typography>{invoiceData.companyName}</Typography>
              <Typography>{invoiceData.street}</Typography>
              <Typography>{invoiceData.city}</Typography>
              <Typography>NIP: {invoiceData.nip}</Typography>
            </>
          }
        />
      )} */}
    </DataCard>
  );
};
