import React, { useState } from "react";
import { Controller } from "react-hook-form";

import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";

import pl from "date-fns/locale/pl";

import { Grid, FormControl, FormHelperText } from "@mui/material";

const FormDatePicker = props => {
  const [isValidValue, setIsValidValue] = useState(true);

  return (
    <Grid item xs={props.xs} sm={props.sm}>
      <Controller
        name={props.name}
        control={props.control}
        required={props.required}
        defaultValue={props.value}
        render={({ field: { onChange, value }, fieldState: { error, invalid } }) => (
          <FormControl fullWidth required={props.required} error={!!error}>
            <DatePicker
              InputAdornmentProps={{ position: "end" }}
              //disableFuture
              //openTo="year"
              //views={["year", "month", "date"]}
              fullWidth
              variant="dialog"
              inputVariant="outlined"
              //inputFormat="DD-MMM-YYYY"
              margin="normal"
              id={props.id}
              name={props.name}
              label={props.label}
              value={value}
              onChange={onChange}
              renderInput={params => <TextField {...params} />}
            />
            {/* <KeyboardDatePicker
                InputAdornmentProps={{ position: "end" }}
                disableFuture
                openTo="year"
                views={["year", "month", "date"]}
                fullWidth
                variant="dialog"
                inputVariant="outlined"
                format="yyyy-MM-DD"
                margin="normal"
                id={props.id}
                name={props.name}
                label={props.label}
                cancelLabel={props.cancelLabel}
                okLabel={props.okLabel}
                invalidDateMessage={props.invalidDateMessage}
                value={value}
                onChange={(date) => {
                  if (date != null) {
                    setIsValidValue(date._isValid);
                  } else {
                    setIsValidValue(true);
                  }
                  return onChange(date);
                }}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                required={props.required}
                error={(error ? true : false) || !isValidValue}
              // helperText={error ? error.message : null}
              /> */}
            {error ? <FormHelperText>{error.message}</FormHelperText> : null}{" "}
          </FormControl>
        )}
        rules={props.rules}
      />
    </Grid>
  );
};

export default FormDatePicker;
