import React from "react";
import { withRouter } from "react-router-dom";
import { Edit as EditIcon, Add as AddIcon, Clear as ClearIcon } from "@mui/icons-material";
import { DataView } from "Components/General/DataView/DataView";
import { getLanguages } from "Services/api/languages/languages";
import { AddEditLanguage } from "./AddEditLanguage";
import { DeleteLanguage } from "./DeleteLanguage";
import { Typography } from "@mui/material";
import { MessagePopup } from "Components/General/Popup/MessagePopup";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DownloadIcon from "@mui/icons-material/Download";
import { UploadLanguage } from "./UploadLanguage";
import { downloadTranslation } from "Services/api/languages/languages";

const Languages = () => {
  const downloadFile = file => {
    const url = URL.createObjectURL(new Blob([file.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file.fileName);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <DataView
      title="JĘZYKI"
      columns={[
        { field: "id", headerName: "ID", flex: 1, filterable: false, sortable: false },
        {
          field: "icon",
          headerName: "Ikona",
          flex: 1,
          filterable: false,
          sortable: false,
          renderCell: params => <img src={params.value} width="30" height="30" alt="icon" />
        },
        { field: "description", headerName: "Język", flex: 1, filterable: false, sortable: false },
        { field: "name", headerName: "Oznaczenie", flex: 1, filterable: false, sortable: false }
      ]}
      actions={[
        {
          type: "row",
          flex: 1,
          label: "Prześlij tłumaczenia",
          title: "Prześlij plik z tłumaczeniami",
          permission: "Languages/UploadTranslation",
          icon: <InsertDriveFileIcon className="GridEditIcon" />,
          onClick: params => <UploadLanguage {...params} />
        },
        {
          type: "row",
          flex: 1,
          label: "Pobierz tłumaczenia",
          title: "Pobierz plik z tłumaczeniami",
          permission: "Languages/DownloadTranslation",
          icon: <DownloadIcon className="GridEditIcon" />,
          onClick: params => {
            downloadTranslation(params.item.id).then(downloadFile);
          }
        },
        {
          type: "row",
          label: "Edytuj",
          title: "Edycja języka",
          permission: "Languages/Edit",
          icon: <EditIcon className="GridEditIcon" />,
          onClick: params => <AddEditLanguage {...params} />
        },
        {
          type: "row",
          label: "Usuń",
          title: "Usunięcie języka",
          permission: "Languages/Delete",
          icon: <ClearIcon className="GridDeleteIcon" />,
          onClick: params =>
            params.data.rowCount > 1 ? (
              <DeleteLanguage {...params} />
            ) : (
              <MessagePopup {...params}>
                <Typography>Nie można usunąć wszystkich języków</Typography>
              </MessagePopup>
            )
        },
        {
          type: "grid",
          permission: "Languages/Add",
          label: "Dodaj język",
          title: "Dodanie nowy język",
          icon: <AddIcon className="AddIcon" />,
          onClick: params => <AddEditLanguage {...params} />
        }
      ]}
      loadData={(filter, sort, limit) => getLanguages(filter, sort, limit)}
      hideFooter
    ></DataView>
  );
};

export default withRouter(Languages);
