import { TextField, Grid } from "@mui/material";

import { Controller } from "react-hook-form";

/**
 *
 * @param props.type typ pola input (valid HTML5 input type).
 * @param props.control dla walidacji
 * @param props.name - nazwa pola
 * @param props.label - opis pola
 * @param props.required - czy wymagane
 * @param props.variant - typ graficzny dostępne opcje: filled | outlined | standard
 * @param props.xs - szerokość pola w grid przy mobile
 * @param props.sm - szerokość pola w grid przy desktop
 * @param props.rules - warunki walidacji
 *
 * @returns pole tekstowe typu input
 */
const FormTextField = (props) => {
  //console.log("props", props);
  return (
    <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.value}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            key={props.name}  
            name={props.name}
            id={props.name}
            label={props.label}
            required={props.required}
            variant={props.variant ? props.variant : "outlined"}
            fullWidth
            multiline={props.multiline}
            value={value}
            onChange={onChange}
            type={props.type}
            disabled={props.disabled}
            error={!!error}
            helperText={error ? error.message : null}
            inputProps={{        
              maxLength: props.maxLength            
            }}
          />
        )}
        rules={props.rules}
      />
    </Grid>
  );
};

export default FormTextField;
