import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles(theme => ({
  cardHeader: {
    color: theme.palette.secondary.main,
    textTransform: "uppercase",
    "& .MuiTypography-root": { fontWeight: "bold" }
  },
  cardContentName: {
    fontWeight: "bold",
    fontSize: 22
  },
  cardContentDescription: {
    fontSize: 16
  },
  submitButton: {
    fontSize: 22,
    fontWeight: "bold",
    textTransform: "none",
    minWidth: 140,
    minHeight: 60,
    borderRadius: 8
  }
}));

export default useStyles;
