import { Checkbox } from "@mui/material";
import React from "react";

export const DisabledCheckbox = ({ checked }) => {
  return checked ? (
    <Checkbox style={{ padding: 0 }} disabled checked={true} />
  ) : (
    <Checkbox style={{ padding: 0 }} disabled checked={false} />
  );
};
