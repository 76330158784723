import React from "react";

import { QuestionPopup } from "Components/General/Popup/QuestionPopup";
import { deleteFaq } from "Services/api/cms/faq";

export const DeleteFaq = ({ popup, action, item }) => {
  const onSubmit = async () => {
    const result = await deleteFaq(item.id);
  };

  return (
    <QuestionPopup popup={popup} onSubmit={onSubmit}>
      {`Czy na pewno chcesz usunąć pytanie ${item?.title}?`}
    </QuestionPopup>
  );
};
