import React from "react";

import { QuestionPopup } from "Components/General/Popup/QuestionPopup";
import { deleteContact } from "Services/api/cms/contact";

export const DeleteContact = ({ popup, action, item }) => {
  const onSubmit = async () => {
    const result = await deleteContact(item.id);
  };

  return (
    <QuestionPopup popup={popup} onSubmit={onSubmit}>
      {`Czy na pewno chcesz usunąć kontakt ${item?.email}?`}
    </QuestionPopup>
  );
};
