import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  popup: {
    "& .MuiPaper-root": { borderRadius: 15 },
    "& .MuiDialogTitle-root + .MuiDialogContent-root": { paddingTop: 5 }
  },
  title: {
    fontSize: 32,
    fontWeight: "bold",
    textTransform: "uppercase"
  },
  actions: {
    justifyContent: "flex-start",
    padding: 24
  },
  submitButton: {
    fontSize: 22,
    fontWeight: "bold",
    textTransform: "none",
    minWidth: 140,
    minHeight: 60,
    borderRadius: 8
  },
  cancelButton: {
    fontSize: 22,
    fontWeight: "bold",
    color: "black",
    textTransform: "none",
    minWidth: 140,
    minHeight: 60,
    borderRadius: 8
  }
}));

export default useStyles;
