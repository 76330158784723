import { Chip, Divider } from "@mui/material";
import React from "react";
import moment from "moment";
import { formatPrice } from "Common/utils/priceUtils";
import { DataCard } from "./DataCard";
import { DataRow } from "./DataRow";
import { getPaymentStatusColor, getPaymentStatusText } from "Common/utils/statusesUtils";

export const PaymentData = ({ payments }) => {
  const changeJson = data => {
    if (data) {
      return <pre>{JSON.stringify(JSON.parse(data), null, 1)}</pre>;
    }
    return data;
  };

  return (
    <DataCard title="Szczegóły płatności">
      {payments?.map((transaction, index) => (
        <div key={transaction?.id}>
          <DataRow title="Typ" data={transaction ? (transaction.type == 0 ? "Płatność" : "Zwrot płatności") : ""} smallDesc />
          <DataRow title="ID" data={transaction?.id} smallDesc />
          <DataRow title="Guid" data={transaction?.guid} smallDesc />
          <DataRow title="Kwota" data={formatPrice(transaction?.price)} smallDesc />
          <DataRow
            title="Status"
            customContent={
              <Chip
                color={getPaymentStatusColor(transaction?.status)}
                style={{ color: "white" }}
                label={getPaymentStatusText(transaction?.status)?.toUpperCase()}
              />
            }
            smallDesc
          />
          <DataRow
            title="Data rozpoczęcia operacji"
            data={moment(transaction?.date).format("DD.MM.YYYY HH:mm:ss")}
            smallDesc
          />
          <DataRow title="Dane providera w postaci json-a" data={changeJson(transaction?.json)} smallDesc />
          {index < payments.length - 1 && <Divider sx={{ height: 10 }} />}
        </div>
      ))}
    </DataCard>
  );
};
