import React from "react";

import { Grid, Paper, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import { FormPopup } from "Components/General/Popup/FormPopup";
import FormTextField from "Components/General/FormTypes/FormTextField";
import FormCheckbox from "Components/General/FormTypes/FormCheckbox";

import { getLanguages } from "Services/api/translations/languages";
import { getTutorialWithTranslations } from "Services/api/cms/tutorials";
import { saveTutorial } from "Services/api/cms/tutorials";
import { updateTutorial } from "Services/api/cms/tutorials";

import { useForm } from "react-hook-form";
import { UploadImage } from "./UploadImage";
import { TranslationEditor } from "../TranslationEditor/TranslationEditor";

export const AddEditTutorial = ({ popup, action, item }) => {
  const form = useForm();

  const [tutorial, setTutorial] = React.useState(null);
  const [translations, setTranslations] = React.useState([]);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    const getTutorialData = async () => {
      if (item) {
        const tutorial = await getTutorialWithTranslations(item.id);
        setTutorial(tutorial);
        setTranslations(tutorial.translations);
      } else {
        const languages = await getLanguages();
        const translations = languages.map(lang => {
          return {
            langId: lang.id,
            langDescription: lang.description,
            title: "",
            content: ""
          };
        });
        setTranslations(translations);
      }
    };
    getTutorialData();
  }, [item]);

  const setPhoto = photo => {
    setTutorial(tutorial => ({ ...tutorial, photo }));
  };

  const onSubmit = async data => {
    const translationsData = translations.map(translation => ({
      ...translation,
      title: data[`title_${translation.langId}`],
      content: data[`content_${translation.langId}`]
    }));

    const modified = {
      id: tutorial?.id,
      photo: tutorial?.photo,
      isPublic: !!data?.isPublic,
      translations: translationsData
    };

    if (tutorial?.photo) {
      setSubmitAttempted(false);
      item != null ? await updateTutorial(modified) : await saveTutorial(modified);
    } else {
      setSubmitAttempted(true);
      return true;
    }
  };

  const onError = error => {
    setErrors(error);
    if (tutorial?.photo) {
      setSubmitAttempted(false);
    } else {
      setSubmitAttempted(true);
    }
  };

  return (
    <FormPopup popup={popup} form={form} onSubmit={onSubmit} onError={onError}>
      <Stack spacing={2}>
        <Paper sx={{ borderRadius: 4, padding: 1 }}>
          <Box>
            <Card variant="contained" sx={{ fontSize: 22 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <UploadImage setPhoto={setPhoto} photo={tutorial?.photo} submitAttempted={submitAttempted} />
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={5} >
                    <FormCheckbox
                      control={form.control}
                      name={"isPublic"}
                      id={0}
                      description={"Aktywny"}
                      value={tutorial?.isPublic}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Paper>
        {translations?.map((translation, index) => (
          <TranslationEditor key={index} translation={translation} form={form} errors={errors} />
        ))}
      </Stack>
    </FormPopup>
  );
};
