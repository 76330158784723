import React from "react";
import { useCallback } from "react";
import { DataCard } from "./DataCard";
import { DataRow } from "./DataRow";

export const UserData = ({ title, data, discounts }) => {
  const getDiscountName = useCallback(
    id => discounts?.find(discount => discount.code === id)?.description,
    [discounts]
  );

  return (
    <DataCard title={title}>
      <DataRow title="Email" data={data?.email} />
      <DataRow title="Imię i nazwisko" data={`${data?.firstName || ""} ${data?.lastName || ""}`} />
      <DataRow
        title="Typ i numer dokumentu"
        data={(!!data?.docType || !!data?.docNumber) && `${data?.docType || ""} ${data?.docNumber || ""}`}
      />
      <DataRow title="Pesel" data={data?.pesel} />
      <DataRow title="Telefon" data={data?.phone} />
      <DataRow title="Domyślna zniżka" data={getDiscountName(data?.discountCode)} />
      {data?.cardNumber && <DataRow title="Numer karty" data={data?.cardNumber} noDivider />}
    </DataCard>
  );
};
