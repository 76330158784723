import { Chip, Divider, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { DataRow } from "./DataRow";
import { getTransactionStatusColor, getTransactionStatusText } from "Common/utils/statusesUtils";
import { formatPrice } from "Common/utils/priceUtils";
import { DisabledCheckbox } from "./DisabledCheckbox";
import { getTransactionTypeName } from "Common/utils/transactionUtils";

export const TransactionData = ({ title, data, noDivider }) => {
  return (
    <>
      <Typography textAlign="center" variant="h6" component="div" padding={1}>
        {title}
      </Typography>
      <Divider />
      <DataRow title="ID" data={data?.id} />
      <DataRow title="Data" data={moment(data?.date).format("DD.MM.YYYY HH:mm:ss")} />
      <DataRow title="Id urządzenia" data={data?.deviceId} />
      <DataRow title="Typ transakcji" data={getTransactionTypeName(data?.transactionType)} />
      <DataRow
        title="Status transakcji"
        customContent={
          <Chip
            color={getTransactionStatusColor(data?.status)}
            style={{ color: "white" }}
            label={getTransactionStatusText(data?.status)?.toUpperCase()}
          />
        }
      />
      <DataRow title="W trakcie przetwarzania" customContent={<DisabledCheckbox checked={data?.inProgress} />} />
      <DataRow title="Łączna wartość transakcji" data={formatPrice(data?.price)} />
      <DataRow title="Identyfikator transakcji nadrzędnej" data={data?.parentTransactionId} noDivider={noDivider} />

      {data?.transactionType === 'return' && <DataRow title="Nr poświadczenia" data={data?.certificateNumber} />}
      {data?.transactionType === 'return' && <DataRow title="Najbliższa stacja" data={data?.nearestStation} />}
      {data?.transactionType === 'return' && <DataRow title="Odległość do najbliższej stacji" data={data?.nearestStationDistance && `${data?.nearestStationDistance} km`} />} 
    </>
  );
};
