import React from "react";
import JoditEditor from "jodit-react";

import { Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import FormTextField from "Components/General/FormTypes/FormTextField";
import { Controller } from "react-hook-form";
import useStyles from "../TranslationEditor/style";

export const TranslationEditor = ({ translation, form, errors, withDescription }) => {
  const classes = useStyles();

  return (
    <Paper key={translation.langId} sx={{ borderRadius: 4, padding: 1 }}>
      <Box>
        <Card variant="contained" sx={{ fontSize: 22 }}>
          <CardHeader title={"JĘZYK " + translation.langDescription} className={classes.cardHeader} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormTextField
                  control={form.control}
                  name={"title_" + translation.langId.toString()}
                  label={"Tytuł"}
                  required={true}
                  rules={{ required: "Pole obowiązkowe" }}
                  value={translation.title ?? ""}
                  maxLength={255}
                />
              </Grid>
              <Grid item xs={12}>
                {withDescription && (
                  <FormTextField
                    control={form.control}
                    name={"shortDescription_" + translation.langId.toString()}
                    label={"Wprowadzenie"}
                    required={true}
                    rules={{ required: "Pole obowiązkowe" }}
                    value={translation.shortDescription ?? ""}
                    maxLength={120}
                  />
                )}
              </Grid>
              <Grid
                item
                xs={12}
                border={errors[`content_${translation.langId}`] ? 1 : 0}
                sx={{ borderColor: "error.main" }}
                style={{ paddingTop: 0, paddingLeft: 0 }}
                marginLeft={2}
                marginTop={4}
              >
                <Controller
                  name={"content_" + translation.langId.toString()}
                  control={form.control}
                  defaultValue={translation.content ?? ""}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <JoditEditor
                      value={value}
                      config={{
                        readonly: false,
                        minHeight: "200px",
                        language: "pl",
                        removeButtons: ["fullsize", "image", "link", "table"],
                        placeholder: "Treść"
                      }}
                      onBlur={onChange} // preferred to use only this option to update the content for performance reasons
                    />
                  )}
                />
              </Grid>
              {errors[`content_${translation.langId}`] && (
                <Grid item>
                  <Typography marginLeft="14px" fontSize="14px" color="error.main">
                    Pole obowiązkowe
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Paper>
  );
};
