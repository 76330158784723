import { axiosClient } from "Services/api/axios";

export async function getFaq(filters, sort, limit) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/cms/faq"
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function getFaqWithTranslations(id) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/cms/faq/" + id
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function saveFaq(data) {
  let [result] = [true];

  await axiosClient({
    method: "POST",
    url: "/cms/faq",
    data: data
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      result = false;
    });

  return result;
}

export async function updateFaq(data) {
  let [result] = [true];
  await axiosClient({
    method: "PUT",
    url: "/cms/faq/" + data.id,
    data: data
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      result = false;
    });
  return result;
}

export async function deleteFaq(id) {
  await axiosClient({
    method: "DELETE",
    url: "/cms/faq/" + id
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}
