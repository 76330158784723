import React from "react";

import { Grid } from "@mui/material";
import { FormPopup } from "Components/General/Popup/FormPopup";
import FormTextField from "Components/General/FormTypes/FormTextField";
import FormSelectField from "Components/General/FormTypes/FormSelectField";
import FormLabel from "Components/General/FormTypes/FormLabel";

import { getUser, saveUser, updateUser } from "Services/api/users/users";
import { getRolesLookup } from "Services/api/roles/roles";
import useUserContext from "Context/useUserContext";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

export const AddEditUser = ({ popup, action, item, clearUserContext }) => {
  const [user, setUser] = React.useState(item);
  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    (async () => setUser(item ? await getUser(item.id) : {}))();
    (async () => setRoles(await getRolesLookup()))();
  }, [item]);

  const history = useHistory();
  const { userContext } = useUserContext();

  const onSubmit = async data => {
    let modified = { ...user, ...data };
    const result = item != null ? await updateUser(modified) : await saveUser(modified);

    if (result && userContext.user.email === modified.email) {
      history.push("/");
      clearUserContext();
    }
  };

  const form = useForm();
  const emailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  return (
    <FormPopup popup={popup} form={form} onSubmit={onSubmit}>
      <Grid container direction="column" spacing={2}>
        <FormTextField
          control={form.control}
          name={"email"}
          label={"Email"}
          required={true}
          maxLength={50}
          rules={{
            required: "Podaj email",
            pattern: { value: emailPattern, message: "Niepoprawny format adresu email" }
          }}
          value={user?.email}
        />
        <FormTextField
          control={form.control}
          name={"firstName"}
          label={"Imię"}
          maxLength={50}
          required={true}
          rules={{ required: "Podaj imię" }}
          value={user?.firstName}
        />
        <FormTextField
          control={form.control}
          name={"lastName"}
          label={"Nazwisko"}
          maxLength={50}
          required={true}
          rules={{ required: "Podaj nazwisko" }}
          value={user?.lastName}
        />
        <FormTextField control={form.control} name={"phone"} label={"Telefon"} maxLength={50} value={user?.phone} />
        <FormSelectField
          control={form.control}
          name={"roleId"}
          label={"Rola"}
          items={roles}
          required={true}
          rules={{ required: "Wybierz rolę" }}
          value={user?.roleId}
        />
        {item != null ? 
        <FormLabel          
          value="Zapis spowoduje wylogowanie pracownika."       
        />        
        :
        null}
      </Grid>
    </FormPopup>
  );
};
