import React from "react";
import { withRouter } from "react-router-dom";

import { DataView } from "Components/General/DataView/DataView";
import { Edit as EditIcon, Add as AddIcon, Clear as ClearIcon } from "@mui/icons-material";

import { AddEditContact } from "./AddEditContact";
import { DeleteContact } from "./DeleteContact";

import { getContacts } from "Services/api/cms/contact";

const Contact = () => {
  // const otherAction = (action, item) => {};

  return (
    <DataView
      title="KONTAKT"
      columns={[
        { field: "id", hide: true },
        { field: "email", headerName: "E-mail", flex: 1.5, sortable: false, filterable: false },
        { field: "topic", headerName: "Opis", flex: 1, sortable: false, filterable: false }
      ]}
      actions={[
        {
          type: "row",
          permission: "Cms/Contact/Edit",
          label: "Edytuj",
          title: "Edytuj kontakt",
          icon: <EditIcon className="GridEditIcon" />,
          onClick: params => <AddEditContact {...params} />
        },
        {
          type: "row",
          permission: "Cms/Contact/Delete",
          label: "Usuń",
          title: "Usuń kontakt",
          icon: <ClearIcon className="GridDeleteIcon" />,
          onClick: params => <DeleteContact {...params} />
        },
        {
          type: "grid",
          permission: "Cms/Contact/Add",
          label: "Dodaj kontakt",
          title: "Dodaj kontakt",
          icon: <AddIcon className="AddIcon" />,
          onClick: params => <AddEditContact {...params} />
        }
      ]}
      loadData={(filters, sort, limit) => getContacts(filters, sort, limit)}
      hideFooter
    ></DataView>
  );
};

export default withRouter(Contact);
