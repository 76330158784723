import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";

import useStyles from "./style";

export const FormPopup = ({ popup, form, onSubmit, onError, onCancel, children }) => {
  const classes = useStyles();
  const[disableSubmit, setDisableSubmit] = useState(false);

  const handleCancel = async () => {
    if (onCancel) await onCancel();

    popup.handleClose(false);
  };

  const handleSubmit = async data => {
    if (disableSubmit)
      return;
      
    if (onSubmit) {
      setDisableSubmit(true);

      const isError = await onSubmit(data);

      !isError && popup.handleClose(true);
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth={"md"} open={true} onClose={handleCancel} className={classes.popup}>
      <DialogTitle>
        <div>
          <Typography className={classes.title}>{popup.title}</Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        {/* <DialogContentText>{popup.description}</DialogContentText>         */}
        {children}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          color="primary"
          variant="contained"
          onClick={form.handleSubmit(handleSubmit, onError)}
          className={classes.submitButton}
          disabled={disableSubmit}
        >
          Zapisz
        </Button>
        <Button variant="outlined" onClick={handleCancel} className={classes.cancelButton}>
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};
