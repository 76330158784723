import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";

import { Done as DoneIcon } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

export const MessagePopup = ({ popup, onSubmit, children, fullWidth }) => {
  const handleClose = () => {
    if (onSubmit) onSubmit();

    popup.handleClose(true);
  };

  return (
    <Dialog open={true} onClose={handleClose} maxWidth="xl" fullWidth={fullWidth}>
      <DialogTitle>
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
        {popup.title}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button
          startIcon={<DoneIcon />}
          color="primary"
          variant="contained"
          onClick={handleClose}
          sx={{ minWidth: "120px" }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
