// React modules
import React, { Suspense, useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

// App.js components
import { Loader } from "Components/General/Loader/Loader";
import { useStyles } from "./useStyles";

import useUserContext from "Context/useUserContext";

// material-ui components
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { LicenseInfo } from "@mui/x-data-grid-pro";

// Components
import Menu from "Components/Menu/index";
import Footer from "Components/General/Footer/Footer";
import Login from "Components/Pages/Login/Login";
import Home from "Components/Pages/Home/index";
import NotFound from "Components/Pages/NotFound/index";
import { ChangePassword } from "Components/Pages/Profile/ChangePassword";
import { ChangeProfile } from "Components/Pages/Profile/ChangeProfile";
import Users from "Components/Pages/Users";
import Roles from "Components/Pages/Roles";
import Records from "Components/Pages/Records";
import Reports from "Components/Pages/Reports";
import Settings from "Components/Pages/Settings";
import Contact from "Components/Pages/CMS/Contact";
import Faq from "Components/Pages/CMS/Faq/Faq";
import Logs from "Components/Pages/Logs";
import News from "Components/Pages/CMS/News/News";
import Tutorials from "Components/Pages/CMS/Tutorials";
import { PasswordResetInit } from "Components/Pages/PasswordReset";
import { PasswordResetProceed } from "Components/Pages/PasswordReset";
import Languages from "Components/Pages/Languages/Languages";
import UnfinishedTransactions from "Components/Pages/UnfinishedTransactions/UnfinishedTransactions";

import navigation from "./App.navigation";
import { setAxiosClientInterceptor } from "Services/api/axios";
import { setAxiosClientAuthHeaders } from "Services/api/axios";
import useAPIError from "Common/hooks/useAPIError";
import Registers from "Components/Pages/Registers";

const authorize = (navigation, permissions) =>
  navigation
    .map(navigationGroup => ({
      ...navigationGroup,
      items: navigationGroup.items
        .filter(item => {
          if (item.items) {
            return item?.items?.filter(elementItem => permissions?.includes(elementItem.permission)).length;
          } else {
            return permissions?.includes(item.permission);
          }
        })
        .map(item => ({
          ...item,
          items: item?.items?.filter(elementItem => permissions?.includes(elementItem.permission))
        }))
    }))
    .filter(navigationGroup => navigationGroup.items.length > 0);

const App = props => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  
  const { addError } = useAPIError();
  const { userContext, setUserContext, clearUserContext } = useUserContext();
  const [isTokenSet, setIsTokenSet] = useState(false);
  const history = useHistory();
  useEffect(
    () => setAxiosClientInterceptor(addError, clearUserContext, history),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    setAxiosClientAuthHeaders(userContext?.token);
    if (userContext?.token) {
      setIsTokenSet(true);
    }
  }, [userContext]);

  const handleToolbarClick = isOpen => {
    setOpen(!open);
  };
  let location = useLocation();

  if (!userContext || location.pathname === "/security/reset-init" || location.pathname === "/security/reset-proceed") {
    return (
      <>
        <CssBaseline />
        <Suspense fallback={<Loader />}>
          <div className={classes.rootLogin}>
            <Box component="main" className={classes.content}>
              <Switch>
                <Route exact path="/security/reset-init">
                  <PasswordResetInit />
                </Route>
                <Route exact path="/security/reset-proceed">
                  <PasswordResetProceed />
                </Route>
                <Route>
                  <Login setUserContext={setUserContext} />
                </Route>
              </Switch>
            </Box>
            <Footer className={classes.footer} />
          </div>
        </Suspense>
      </>
    );
  }

  const authorizedNavigation = authorize(navigation, userContext.user.permissions);

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_LICENSE_KEY);

  return (
    <>
      <CssBaseline />
      {isTokenSet ? (
        <Suspense fallback={<Loader />}>
          <div className={open === true && userContext ? classes.rootShift : classes.root}>
            <Menu
              position="fixed"
              navigation={authorizedNavigation}
              onToolbarClick={handleToolbarClick}
              isDrawerOpen={open}
              user={userContext.user}
              clearUserContext={clearUserContext}
            />
            <Box component="main" className={classes.content}>
              <Switch>
                <Route path="/user-change-pwd">
                  <ChangePassword clearUserContext={clearUserContext} />
                </Route>
                <Route path="/user-change-data">
                  <ChangeProfile user={userContext.user} clearUserContext={clearUserContext} />
                </Route>
                <Route path="/users">
                  <Users clearUserContext={clearUserContext} />
                </Route>
                <Route path="/roles">
                  <Roles />
                </Route>
                <Route path="/sale">
                  <Records />
                </Route>
                <Route path="/reports">
                  <Reports token={userContext?.token} />
                </Route>
                <Route path="/settings">
                  <Settings />
                </Route>
                <Route path="/languages">
                  <Languages />
                </Route>
                <Route path="/tutorial">
                  <Tutorials />
                </Route>
                <Route path="/news">
                  <News />
                </Route>
                <Route path="/faq">
                  <Faq />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/logs">
                  <Logs />
                </Route>
                <Route path="/registers">
                  <Registers />
                </Route>
                <Route path="/unfinished-transactions">
                  <UnfinishedTransactions />
                </Route>
                <Route path="/">
                  <Home />
                </Route>
                <Route component={NotFound} />
                {/* <Route component={Unauthorized} /> */}
              </Switch>
            </Box>
            <Footer className={classes.footer} />
          </div>
        </Suspense>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default App;
