import { Button, Divider, Stack, Grid } from "@mui/material";
import FormTextField from "Components/General/FormTypes/FormTextField";
import React from "react";
import moment from "moment";
import { DataCard } from "./DataCard";
import { DataRow } from "./DataRow";
import { saveRemark, getRemarks } from "Services/api/records/records";
import { useForm } from "react-hook-form";


const AddNewRemark = ({ ticketId, onAdded }) => {
 
    const handleSubmit = async data => { 
        await saveRemark(ticketId, data?.description);    
        form.setValue('description', '');
        if (onAdded) onAdded();
    };

    const form = useForm();

    return ( <Stack direction={"row"} sx={{m: '20px 10px'}}>
                <FormTextField
                      control={form.control}
                      name={"description"}
                      label={"Opis"}
                      required={true}
                      maxLength={200}
                      multiline
                      rules={{
                        required: "Podaj opis komentarza",
                        validate: value => value?.trim().length || "Podaj opis komentarza"
                      }}
                    />               
                <Button variant="contained" sx={{m: '1px 10px', height: "41px", width: "180px"}}  onClick={form.handleSubmit(handleSubmit)}>Dodaj nowy</Button>
            </Stack>
    );
}


export const Remarks = ({ ticketId, items }) => {

    const [remarks, setRemarks] = React.useState(items);

    const  refreshHandler = async () => {
        const response = await getRemarks(ticketId);
        if (response !== undefined) setRemarks(response);
    };

  return (
    <DataCard title="Komentarze">
      <>
        {remarks?.map((remark, index) => (
            <div key={remark?.id}>
                <Grid container>
                    <Grid item xs={6}>
                        <DataRow title="Użytkownik" data={remark?.userName} />
                    </Grid>
                    <Grid item xs={6}>
                        <DataRow title="Wprowadzono" data={moment(remark?.date).format("DD.MM.YYYY HH:mm")} />
                    </Grid>                    
                </Grid>
                <DataRow title="Opis" data={remark?.description} smallDesc />             
                <Divider sx={{ height: 2, backgroundColor: 'lightgray' }}  />
            </div>
        ))}
        { remarks?.length < 3 ? <AddNewRemark ticketId={ticketId} onAdded={refreshHandler}/> : null }
      </>
    </DataCard>
  );
};
