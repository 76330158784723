import GroupIcon from "@mui/icons-material/Group";
import ReceiptIcon from "@mui/icons-material/Receipt";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
// import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
// import SyncAltIcon from '@mui/icons-material/SyncAlt';
//import CameraIcon from "@mui/icons-material/Camera";
import ListIcon from "@mui/icons-material/List";
import NoteIcon from "@mui/icons-material/Note";
import TranslateIcon from "@mui/icons-material/Translate";
//import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import DescriptionIcon from "@mui/icons-material/Description";
import ContactsIcon from "@mui/icons-material/Contacts";
import CancelIcon from "@mui/icons-material/Cancel";


const navigation = [
  {
    icon: "",
    title: "OGÓLNE",
    link: "general",

    items: [
      {
        title: "Podsumowanie",
        link: "/",
        permission: "Summary",
        isPublic: false,
        icon: <DashboardIcon />
      },
      {
        title: "Rejestr sprzedaży",
        link: "sale",
        permission: "Sale",
        isPublic: false,
        icon: <ShoppingBagIcon />
      },
      {
        title: "Raporty",
        link: "reports",
        permission: "Reports",
        isPublic: false,
        icon: <DescriptionIcon />
      }
    ]
  },
  {
    icon: "",
    title: "ZARZĄDZANIE",
    link: "administration",

    items: [
      {
        title: "Pracownicy",
        link: "users",
        permission: "Users",
        isPublic: false,
        icon: <GroupIcon />
      },
      {
        title: "Role i uprawnienia",
        link: "roles",
        permission: "Roles",
        isPublic: false,
        icon: <StopCircleIcon />
      },
      {
        title: "Ustawienia",
        link: "settings",
        permission: "Settings",
        isPublic: false,
        icon: <SettingsIcon />
      }
      // {
      //   title: "Zwroty",
      //   link: "returns",
      //   permission: "Return",
      //   isPublic: false,
      //   icon: <KeyboardReturnIcon />
      // },
      // {
      //   title: "Wymiany",
      //   link: "exchanges",
      //   permission: "Exchange",
      //   isPublic: false,
      //   icon: <SyncAltIcon />
      // },
    ]
  },
  {
    icon: "",
    title: "NARZĘDZIA MONITORUJĄCE",
    link: "monitoring",
    items: [
      {
        title: "Statusy rejestracji",
        link: "registers",
        permission: "Registers",
        isPublic: false,
        icon: <ContactsIcon />
      },
      {
        title: "Niesfinalizowane transakcje",
        link: "unfinished-transactions",
        permission: "UnfinishedTransactions",
        isPublic: false,
        icon: <CancelIcon />
      }
    ]
  },
  {
    icon: "",
    title: "APLIKACJA MOBILNA",
    link: "mobile",

    items: [
      // {
      //   title: "Oferty",
      //   link: "offers",
      //   permission: "Offers",
      //   isPublic: false,
      //   icon: <LocalOfferIcon />
      // },
      {
        title: "Języki",
        link: "languages",
        permission: "Languages",
        isPublic: false,
        icon: <TranslateIcon />
      },
      {
        title: "CMS",
        link: "cms",
        permission: "Cms",
        isPublic: false,
        icon: <NoteIcon />,
        items: [
          {
            title: "Kontakt",
            link: "contact",
            permission: "Cms/Contact",
            isPublic: false
          },
          {
            title: "FAQ",
            link: "faq",
            permission: "Cms/Faq",
            isPublic: false
          },
          {
            title: "Wiadomości",
            link: "news",
            permission: "Cms/News",
            isPublic: false
          },
          {
            title: "Samouczek",
            link: "tutorial",
            permission: "Cms/Tutorial",
            isPublic: false
          }
        ]
      },
      {
        title: "Logi",
        link: "logs",
        permission: "Logs",
        isPublic: false,
        icon: <ListIcon />
      }
    ]
  }
];

export default navigation;
