import moment from "moment";
import React from "react";
import { formatPrice } from "Common/utils/priceUtils";
import { DataCard } from "./DataCard";
import { DataRow } from "./DataRow";

const inBrackets = (data) => data ? `(${data})` : data;

export const TicketData = ({ ticket }) => {
  return (
    <DataCard title="Dane biletu">
      <DataRow title="Numer seryjny" data={ticket?.number} />
      <DataRow title="Oferta" data={`${ticket?.offerCode} ${inBrackets(ticket?.offerName)}`} />
      <DataRow title="Ulga" data={`${ticket?.discountCode} ${inBrackets(ticket?.discountName)}`} />
      <DataRow title="Cena" data={formatPrice(ticket?.price)} />
      <DataRow title="Liczba pasażerów" data={ticket?.passengers} />
      {ticket?.kind === "zone" && <DataRow title="Strefa" data={ticket?.zone} />}
      {ticket?.kind === "route" && <DataRow title="Relacja" data={ticket?.relation} />}
      {ticket?.kind === "route" && (
        <DataRow title="Długośc trasy" data={ticket?.distance && `${ticket?.distance} km`} />
      )}
      <DataRow title="Ważny od" data={moment(ticket?.validFrom).format("DD.MM.YYYY HH:mm")} />
      <DataRow title="Ważny do" data={moment(ticket?.validTo).format("DD.MM.YYYY HH:mm")} />
      <DataRow title="Przewoźnik" data={ticket?.carrier} />
      <DataRow title="Pociąg" data="Osobowy - Klasa 2" />
    </DataCard>
  );
};
