import React from "react";

import { QuestionPopup } from "Components/General/Popup/QuestionPopup";
import { deleteTutorial } from "Services/api/cms/tutorials";

export const DeleteTutorial = ({ popup, action, item }) => {
  const onSubmit = async () => {
    const result = await deleteTutorial(item.id);
  };

  return (
    <QuestionPopup popup={popup} onSubmit={onSubmit}>
      {`Czy na pewno chcesz usunąć samouczek ${item?.title}?`}
    </QuestionPopup>
  );
};
