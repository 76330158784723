import { axiosClient } from "Services/api/axios";
import { extractFileName } from "Common/utils/filesUtils";

export async function getRecords(filter, sort, limit) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/tickets/records",
    params: { filter: filter, sort: sort, limit: limit }
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function getTicketDetails(id) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: `/tickets/records/${id}/details`
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function downloadReturnForm(id) {
  return await axiosClient({
    method: "GET",
    url: `/tickets/records/${id}/details/return-form`,
    responseType: "blob"
  })
    .then(response => {
      console.log(response.data);
      return { data: response.data, fileName: extractFileName(response.headers["content-disposition"]) };
    })
    .catch(error => {
      console.log(error);
    });
} 

export async function getTransactionDetails(guid) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: `/tickets/records/${guid}/payments`
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}


export async function setProhibitInvoicing(id, state) {
  return axiosClient({
    method: "PATCH",
    url: `/tickets/records/${id}/prohibit-invoicing`,
    params: { state: state }
})
    .then((response) => {
        console.log(response.data);
        return response.data;
    })
    .catch((error) => {
        console.log(error);
    });    
}

export async function getRemarks(id) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: `/tickets/records/${id}/remarks`
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function saveRemark(id, description) {
  return axiosClient({
    method: "POST",
    url: `/tickets/records/${id}/remarks`,
    data: description
})
    .then((response) => {
        console.log(response.data);
        return response.data;
    })
    .catch((error) => {
        console.log(error);
    });    
}