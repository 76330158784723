import React from "react";

import { Grid } from "@mui/material";

import { FormPopup } from "Components/General/Popup/FormPopup";
import FormTextField from "Components/General/FormTypes/FormTextField";
import FormLabel from "Components/General/FormTypes/FormLabel";
import FormMultiSelectField from "Components/General/FormTypes/FormMultiSelectField";

import { getRolePermissions } from "Services/api/roles/roles";
import { updateRole } from "Services/api/roles/roles";

import { useForm } from "react-hook-form";

export const EditRole = ({ popup, action, item }) => {
  const [permissions, setPermissions] = React.useState(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getRolePermissions(item);

      if (response !== undefined) setPermissions(response);
    };

    fetchData();
  }, [item]);

  const onSubmit = async data => {
    console.log("on submit", data);
    updateRole(item.id, data.name, data.description, data.permissions);
  };

  const form = useForm();

  return permissions ? (
    <FormPopup popup={popup} form={form} onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <FormTextField
          control={form.control}
          name={"name"}
          label={"Nazwa"}
          required={true}
          maxLength={50}
          value={item?.name ? item.name : ""}
          rules={{ required: "Podaj nazwę" }}
          xs={11}
          sm={11}
        />
        <FormTextField
          control={form.control}
          name={"description"}
          label={"Opis"}
          required={true}
          maxLength={50}
          value={item?.description ? item.description : ""}
          rules={{ required: "Podaj opis" }}
          xs={11}
          sm={11}
        />
        <FormMultiSelectField
          multiple={true}
          control={form.control}
          name={"permissions"}
          items={permissions}
          label={"Uprawnienia"}
          required={true}
          value={permissions.filter(x => x.isAssigned === true).map(x => x.id)}
          rules={{ required: "Podaj uprawnienie" }}
          xs={11}
          sm={11}
        />
        {item != null ? 
        <FormLabel          
        value="Zapis spowoduje wylogowanie wszystkich użytkowników przypisanych do tej roli."
        xs={11}
        sm={11}
        />               
        :
        null}        
      </Grid>
    </FormPopup>
  ) : null;
};
