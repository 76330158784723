import React from "react";
import useStyles from "./style";

import FormTextField from "Components/General/FormTypes/FormTextField";
import FormPasswordField from "Components/General/FormTypes/FormPasswordField";
import FormSubmitButton from "Components/General/FormTypes/FormSubmitButton";
import { ChangeUserPassword } from "Services/api/security/security";
import useUserContext from "Context/useUserContext";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Grid, Container, Typography } from "@mui/material";

export const ChangePassword = props => {
  const classes = useStyles();
  let history = useHistory();

  const { handleSubmit, control, getValues } = useForm();

  const { userContext } = useUserContext();

  const onSubmit = async data => {
    let response = await ChangeUserPassword(data.email, data.oldPassword, data.newPassword);
    if (response !== undefined) {
      history.push("/");
      props.clearUserContext();
    }
  };

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Typography className={classes.title} component="h1" variant="h5">
        Zmiana hasła
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <FormTextField
            control={control}
            name={"email"}
            label={"Email"}
            required={true}
            disabled
            maxLength={50}
            value={userContext.user.email}
          />

          <FormPasswordField
            control={control}
            label={"Stare hasło"}
            required={true}
            name={"oldPassword"}
            rules={{ required: "Podaj stare hasło" }}
          />

          <FormPasswordField
            control={control}
            label={"Nowe hasło"}
            required={true}
            name={"newPassword"}
            rules={{
              required: "Podaj nowe hasło"
            }}
          />

          <FormPasswordField
            control={control}
            label={"Powtórz hasło"}
            required={true}
            name={"repeatPassword"}
            rules={{
              required: "Podaj powtórnie hasło",
              validate: value => value === getValues("newPassword") || "Hasła muszą być takie same"
            }}
          />

          <FormSubmitButton>Zmień hasło</FormSubmitButton>
        </Grid>
      </form>
    </Container>
  );
};
