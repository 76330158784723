import React, { useEffect, useState } from "react";
import { MessagePopup } from "Components/General/Popup/MessagePopup";
import { getTransactionDetails } from "Services/api/records/records";
import { CircularProgress, Grid } from "@mui/material";
import { UserData } from "./components/UserData";
import { PaymentData } from "./components/PaymentData";
import { Box } from "@mui/system";
import { getDiscounts } from "Services/api/dictionary/dictionary";

export const PaymentDetails = ({ popup, onSubmit, item }) => {
  const [details, setDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [discounts, setDiscounts] = useState([]);
  const { buyer, cardOwner, payments } = details;

  useEffect(() => {
    const fetchTtransactionDetails = async () => {
      const data = await getTransactionDetails(item.ticketId);
      setDetails(data);
      setIsLoading(false);
    };
    const fetchDiscounts = async () => {
      const data = await getDiscounts();
      setDiscounts(data);
      setIsLoading(false);
    };
    fetchTtransactionDetails();
    fetchDiscounts();
  }, []);

  return (
    <MessagePopup popup={popup} onSubmit={onSubmit} fullWidth>
      {isLoading ? (
        <Box textAlign="center">
          <CircularProgress />
        </Box>
      ) : (
        <Grid justifyContent="center" container padding={2} spacing={4}>
          <Grid item xs={cardOwner ? 6 : 12}>
            <UserData title="Dane kupującego" data={buyer} discounts={discounts} />
          </Grid>
          {cardOwner && (
            <Grid item xs={6}>
              <UserData title="Dane właściciela karty" data={cardOwner} discounts={discounts} />
            </Grid>
          )}
          <Grid item xs={12}>
            <PaymentData payments={payments} />
          </Grid>
        </Grid>
      )}
    </MessagePopup>
  );
};
