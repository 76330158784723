import { axiosClient } from "Services/api/axios";

export async function LoginUser(email, password) {
    let [resData,resError] = [undefined, undefined]; 

    await axiosClient({
        method: "POST",
        url: "/security/login",
        data: { email, password }
    })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            resError = error;
        });

        return { data:resData, error:resError };
}

export async function ResetPasswordInit(email) {
    
    return axiosClient({
        method: "PATCH",
        url: "/security/password/reset-init",
        data: {email}       
    })
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });    
}

export async function ResetPasswordConfirm(password1, password2, userToken, operationToken) {
    
    return axiosClient({
        method: "PATCH",
        url: "/security/password/reset-confirm",
        data: { password1, password2, userToken, operationToken }
    })
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });    
}

export function LogoutUser() {
    axiosClient({
        method: "POST",
        url: "/security/logout",
    });
}

export function GetUserContext() {
    return axiosClient({
        method: "GET",
        url: "/security/login"
    })
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}

export function ChangeUserPassword(email, oldPassword, newPassword) {    
    return axiosClient({
        method: "PATCH",
        url: "/security/password/change",
        data: { oldPassword, newPassword }
    })
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
        });
}
