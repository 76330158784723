import React from "react";
import { withRouter } from "react-router-dom";

import { DataView } from "Components/General/DataView/DataView";
import { Edit as EditIcon, Add as AddIcon, Clear as ClearIcon } from "@mui/icons-material";

import { AddEditNews } from "./AddEditNews";
import { DeleteNews } from "./DeleteNews";

import { getNews } from "Services/api/cms/news";
import moment from "moment";

const News = () => {
  return (
    <DataView
      title="WIADOMOŚCI"
      columns={[
        { field: "id", hide: true },
        { field: "title", headerName: "Tytuł", flex: 1.5, sortable: false, filterable: false },
        {
          field: "publishDate",
          headerName: "Data publikacji",
          flex: 1,
          sortable: false,
          filterable: false,
          renderCell: params => moment(params.value).format("DD.MM.YYYY HH:mm")
        },
        {
          field: "expiryDate",
          headerName: "Data wygaśnięcia",
          flex: 1,
          sortable: false,
          filterable: false,
          renderCell: params => (params.value ? moment(params.value).format("DD.MM.YYYY HH:mm") : "-")
        }
      ]}
      actions={[
        {
          type: "row",
          permission: "Cms/News/Edit",
          label: "Edytuj",
          title: "Edytuj wiadomość",
          icon: <EditIcon className="GridEditIcon" />,
          onClick: params => <AddEditNews {...params} />
        },
        {
          type: "row",
          permission: "Cms/News/Delete",
          label: "Usuń",
          title: "Usuń wiadomość",
          icon: <ClearIcon className="GridDeleteIcon" />,
          onClick: params => <DeleteNews {...params} />
        },
        {
          type: "grid",
          permission: "Cms/News/Add",
          label: "Dodaj wiadomość",
          title: "Dodaj wiadomość",
          icon: <AddIcon className="AddIcon" />,
          onClick: params => <AddEditNews {...params} />
        }
      ]}
      loadData={(filters, sort, limit) => getNews(filters, sort, limit)}
      hideFooter
    ></DataView>
  );
};

export default withRouter(News);
