import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import useStyles from "./style";

export const RedirectPopup = props => {
  const [showPopup, setShowPopup] = useState(true);
  const classes = useStyles();
  let history = useHistory();

  const closePopup = () => {
    if (typeof props.onClose === "function") {
      props.onClose();
    }

    setShowPopup(false);
  };

  const redirect = redirect => {
    history.push(redirect);
  };

  return (
    <>
      {showPopup && (
        <Dialog open={true} onClose={() => closePopup()}>
          <DialogTitle>
            <div>
              <Typography className={classes.title}>{props.title}</Typography>
            </div>
          </DialogTitle>
          <DialogContent>{props.description}</DialogContent>
          <DialogActions className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => redirect(props.redirect)}
              className={classes.submitButton}
            >
              {props.redirectButton}
            </Button>
            {props.cancelButton && (
              <Button variant="outlined" onClick={() => closePopup()} className={classes.cancelButton}>
                {props.cancelButton}
              </Button>
            )}
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
