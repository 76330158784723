import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

export const UploadImage = props => {
  const onChange = async e => {
    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
      });
    }
    props.setPhoto(await blobToBase64(e.target.files[0]));
  };

  return (
    <>
      <Grid
        marginLeft="8px"
        border={1}
        borderColor={props.submitAttempted ? "error.main" : "rgba(0,0,0,0.23)"}
        borderRadius="4px"
        spacing={4}
        item
        container
        xs={props?.xs ? props.xs : 12}
        sm={props?.sm ? props.sm : 12}
        alignItems="center"
        justifyContent="center"
        padding={4}
      >
        {props.photo ? (
          <Grid item style={{ paddingTop: 0, paddingLeft: 0 }} marginRight={4}>
            <img src={props.photo} width="100" height="100" alt="zdjęcie" />
          </Grid>
        ) : (
          <Box
            width="100px"
            height="100px"
            backgroundColor="grey.300"
            borderRadius="8px"
            sx={{ border: "1px dashed #8b8b8b" }}
          ></Box>
        )}
        <Grid item paddingLeft={4} style={{ paddingTop: 0 }}>
          <label htmlFor="upload-photo" style={{ cursor: "pointer", marginBottom: 0 }}>
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={onChange}
              accept="image/*"
            />

            <Box
              borderRadius="8px"
              border={1}
              padding={2}
              marginY={2}
              borderColor="primary.main"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <InsertDriveFileIcon color="primary" />
              <Typography marginLeft={2} color="primary">
                {props.photo ? "Zmień zdjęcie" : "Dodaj zdjęcie"}
              </Typography>
            </Box>
          </label>
        </Grid>
      </Grid>
      {props.submitAttempted && (
        <Grid item>
          <Typography marginLeft="14px" fontSize="14px" color="error.main">
            Pole obowiązkowe
          </Typography>
        </Grid>
      )}
    </>
  );
};
