import { axiosClient } from "Services/api/axios";

export async function getSettings() {
    let [resData] = [undefined];

    await axiosClient({
        method: "GET",
        url: "/settings"
    })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

    return resData;
}

export async function editSettings(settings) {
    let [resData] = [undefined];

    await axiosClient({
        method: "PUT",
        url: "/settings",
        data: settings 
    })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

    return resData;
}
