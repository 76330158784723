import React from "react";
import Resizer from "react-image-file-resizer"
import { Box, Grid, Typography } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

export const IconUploader = props => {
  const onChange = async e => {
    function blobToBase64(blob) {
      return new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => resolve(reader.result);
      });
    }
    const file = e.target.files[0];
    const image = await resizeFile(file);
    props.setIcon(image);
  };

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 30, 30, 'JPEG', 50, 0,
    uri => {
      resolve(uri);
    }, 'base64' );
});

  return (
    <>
      <Grid
        marginTop={2}
        marginLeft="8px"
        border={1}
        borderColor={props.submitAttempted ? "error.main" : "rgba(0,0,0,0.23)"}
        borderRadius="4px"
        spacing={4}
        item
        container
        xs={props?.xs ? props.xs : 12}
        sm={props?.sm ? props.sm : 12}
        alignItems="center"
        style={{ paddingTop: 0 }}
      >
        {props.icon && (
          <Grid item style={{ paddingTop: 0, paddingLeft: 0 }} marginRight={4}>
            <img src={props.icon} width="30" height="30" alt="icon" />
          </Grid>
        )}
        <Grid item style={{ paddingTop: 0, paddingLeft: 0 }}>
          <label htmlFor="upload-photo" style={{ cursor: "pointer", marginBottom: 0 }}>
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              onChange={onChange}
              accept=".png"
            />

            <Box
              borderRadius="8px"
              border={1}
              padding={2}
              marginY={2}
              borderColor="primary.main"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <InsertDriveFileIcon color="primary" />
              <Typography marginLeft={2} color="primary">
                {props.icon ? "Zmień ikonę" : "Dodaj ikonę"}
              </Typography>
            </Box>
          </label>
        </Grid>
      </Grid>
      {props.submitAttempted && (
        <Grid item>
          <Typography marginLeft="14px" fontSize="14px" color="error.main">
            Dodaj ikonę
          </Typography>
        </Grid>
      )}
    </>
  );
};
