import React from "react";
import { withRouter } from "react-router-dom";

import { DataView } from "Components/General/DataView/DataView";
import { Edit as EditIcon, Add as AddIcon, Clear as ClearIcon } from "@mui/icons-material";
import { getUsers } from "Services/api/users/users";
import { AddEditUser } from "./AddEditUser";
import { DeleteUser } from "./DeleteUser";

const Users = ({ clearUserContext }) => {
  // const otherAction = (action, item) => {};

  return (
    <DataView
      title="PRACOWNICY"
      columns={[
        { field: "id", hide: true },
        { field: "email", headerName: "E-mail", flex: 1.5 },
        { field: "firstName", headerName: "Imię", flex: 1 },
        { field: "lastName", headerName: "Nazwisko", flex: 1.5 },
        { field: "phone", headerName: "Telefon", flex: 1 },
        { field: "role", headerName: "Rola", flex: 1 }
      ]}
      actions={[
        {
          type: "row",
          permission: "Users/Edit",
          label: "Edytuj",
          title: "Edycja pracownika",
          icon: <EditIcon className="GridEditIcon" />,
          onClick: params => <AddEditUser {...params} clearUserContext={clearUserContext} />
        },
        {
          type: "row",
          permission: "Users/Delete",
          label: "Usuń",
          title: "Usunięcie danych użytkownika",
          icon: <ClearIcon className="GridDeleteIcon" />,
          onClick: params => <DeleteUser {...params} clearUserContext={clearUserContext} />
        },
        {
          type: "grid",
          permission: "Users/Add",
          label: "Dodaj pracownika",
          title: "Dodanie nowego użytkownika",
          icon: <AddIcon className="AddIcon" />,
          onClick: params => <AddEditUser {...params} clearUserContext={clearUserContext} />
        }
        // {
        //   type: "grid",
        //   label: "Inna akcja",
        //   title: "Inna akcja",
        //   icon: <AddIcon />,
        //   onClick: (params) => otherAction(...params),
        // },
      ]}
      loadData={(filter, sort, limit) => getUsers(filter, sort, limit)}
    ></DataView>
  );
};

export default withRouter(Users);
