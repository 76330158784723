import React, { useEffect } from 'react';
import { useMount } from 'react-use';
import useStyles from "./style";
import { Grid } from "@mui/material";

const ReportViewer = ({
    token,
    reportName
}) => {

    useMount(() => {
        window.jQuery.ajaxPrefilter(function (options) {
            if (!options.beforeSend) {
                options.beforeSend = function (xhr) {
                    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
                    xhr.setRequestHeader('X-ApiKey', 'F6F5CCEA_EB69_4A0A_B469_49D51B989CD1.web');
                }
            }
        });

        let reportViewer = window.jQuery('#reportViewer1');

        reportViewer.telerik_ReportViewer({
            //serviceUrl: "/telerik/reports",
            serviceUrl: process.env.REACT_APP_BOL_API_URL + "reports",
            reportSource: {
                report: `${reportName}`
            },
            parameterEditors: [
                {
                    match: function (parameter) {
                        return parameter.name === 'DateTimeFrom' || parameter.name === 'DateTimeTo';
                    },
                    createEditor: function (placeholder, options) {
                        var dateTimePickerElement = window.jQuery(placeholder).html('<input type="datetime"/>'),
                            parameter,
                            valueChangedCallback = options.parameterChanged,
                            dateTimePicker;

                        function onChange() {
                            var dtv = this.value();
                            if (null !== dtv) {
                                dtv = myadjustTimezone(dtv);
                            }
                            valueChangedCallback(parameter, dtv);
                        }
                        return {
                            beginEdit: function (param) {
                                parameter = param;

                                var dt = null;
                                try {
                                    if (param.value) {
                                        dt = myunadjustTimezone(param.value);
                                    }
                                } catch (e) {
                                    dt = null;
                                }

                                window.jQuery(dateTimePickerElement).find("input").kendoDateTimePicker({
                                    // format: "dd/MM/yyyy HH:mm:ss",
                                    value: dt,
                                    change: onChange
                                });
                                dateTimePicker = window.jQuery(dateTimePickerElement).find("input").data("kendoDateTimePicker");
                            }
                        };
                    }
                }],
            parameters: {
                editors: {
                    singleSelect: "COMBO_BOX",
                    multiSelect: "COMBO_BOX",
                }
            },
            documentMapVisible: false,
            scaleMode: "FIT_PAGE_WIDTH",
            scale: 1.0,
            viewMode: "INTERACTIVE",
            //viewMode: "PRINT_PREVIEW",
            enableAccessibility: false,
            ready: function () {
                //window.jQuery("[data-command*='telerik_ReportViewer_togglePrintPreview']").parent().hide();
                window.jQuery("[data-command*='telerik_ReportViewer_historyBack']").parent().hide();
                window.jQuery("[data-command*='telerik_ReportViewer_historyForward']").parent().hide();

                var splitter = window.jQuery("#reportViewer1").find(".k-splitter").data("kendoSplitter")

                splitter.options.panes[1].size = "250px";// use array index 1 for Parameter area and 0 for Document Map area

                splitter.resize(true);
            },
            sendEmail: { enabled: false },
            error: function (e, args) {
                console.log("This event handler will be called after a page of the report is ready.");
                console.log("The error message is: " + args);
            }
        });

        //functions required for adjusting the Date and Time selection to avoid sending and receiving dates, which are not in UTC
        function myadjustTimezone(date) {
            return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()));
        };
        function myunadjustTimezone(date) {
            return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
        };

    });

    useEffect(() => {
        let reportViewer = window.jQuery('#reportViewer1');
        let reportViewerData = reportViewer.data("telerik_ReportViewer");
        reportViewerData.reportSource({
            report: `${reportName}`
        });
    }, [reportName])

    const classes = useStyles();

    return (
        <div className={classes.report} sx={{ flexGrow: 1 }} id="reportViewer1"></div>
    );
};


export default ReportViewer;
