export const extractFileName = string => {
    let parts = string.split(';');
    parts.forEach(function(part, index){this[index] = part.trim();},parts);
    let fileName = parts.find(x => x.startsWith("filename="));
    let fileNameTrimmed = fileName.split('=')[1].replace('"','').replace('"','');    
    return fileNameTrimmed;
  };


  export const downloadFile = file => {
    const url = URL.createObjectURL(new Blob([file.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", file.fileName);
    document.body.appendChild(link);
    link.click();
  };