import React from 'react';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
  } from "@mui/material";


const asInputOption = (report) => report && ({
    value: report,
    label: report.name
});

const ReportTypeField = ({
    value,
    options,
    onChange
}) => {
    
    return (
        <FormControl fullWidth>
            <InputLabel>Wybierz raport</InputLabel>
            <Select

                id="report-selection"
                value={value}
                label="Wybierz raport"
                onChange={x => onChange(x.target.value)}
            >
                 {
                    options.map((option) => (
                    <MenuItem key={option.id} value={option}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default ReportTypeField;
