import React from "react";
import Typography from "@mui/material/Typography";
import useStyles from "./style";

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Typography variant="body1">
        © ASEC s.a. v.{process.env.REACT_APP_MAJOR_VERSION}{process.env.REACT_APP_BUILD_VERSION}.{process.env.NODE_ENV}
      </Typography>
    </footer>
  );
};

export default Footer;
