import { Typography, Grid } from "@mui/material";

const FormLabel = (props) => {  
  return (
    <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12}>
      <Typography >{props.value}</Typography>      
    </Grid>
  );
};

export default FormLabel;
