import { axiosClient } from "Services/api/axios";

export async function getDiscounts() {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/dictionary/discounts"
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}
