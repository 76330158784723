import React from "react";

import Meta from "Components/General/Meta/index";
import { DataGridPro, GridPagination, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useUserContext from "Context/useUserContext";
import { TICKET_STATUS_VALUE } from "Common/utils/statusesUtils";
import { PAYMENT_STATUS_VALUE } from "Common/utils/statusesUtils";
import { useMemo } from "react";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

export const currencyColumn = {
  type: "number",
  width: 130,
  valueFormatter: ({ value }) =>
    new Intl.NumberFormat("pl-PL", {
      style: "currency",
      currency: "PLN"
    }).format(Number(value))
};

export const dateColumn = {
  type: "datetime",
  width: 130,
  valueFormatter: ({ value }) =>
    new Intl.DateTimeFormat("pl-PL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    }).format(Date.parse(value))
};

export const dateTimeColumn = {
  type: "datetime",
  width: 130,
  valueFormatter: ({ value }) =>
    new Intl.DateTimeFormat("pl-PL", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit"
    }).format(Date.parse(value))
};

export const actionsColumn = {
  type: "actions",
  width: 100
};

const HeaderComponent = props => {
  return (
    <Grid container direction="row" padding={"0 0 20px 0"}>
      <Grid item xs={6} marginTop="10px" marginBottom="10px">
        <Stack spacing={1} direction="row" justifyContent="flex-start">
          <Meta subTitle={props.title}></Meta>
          <Typography component="h1" variant="h5">
            {props.title}
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={6} container justifyContent="flex-end">
        {props.gridActions}
      </Grid>
    </Grid>
  );
};

const FooterComponent = props => {
  return (
    <Grid container direction="row">
      <Grid item xs={6}>
        <Stack spacing={1} direction="row" justifyContent="flex-start"></Stack>
      </Grid>
      <Grid item xs={6} container justifyContent="flex-end" style={{ padding: "14px 30px 14px 0" }}>
        <GridPagination className="DataGridPagination" />
      </Grid>
    </Grid>
  );
};

export const DataView = ({
  title,
  columns,
  actions,
  loadData,
  style,
  hideFooter,
  initialSort = [],
  initialFilter = null,
  mapStatus,
  withOrderColumn,
  changeOrder,
  dataRefresh
}) => {
  const [refresh, setRefresh] = React.useState(null);
  const [filter, setFilter] = React.useState(initialFilter);
  const [order, setOrder] = React.useState(initialSort);
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [data, setData] = React.useState({ rowsCount: 0, rows: [] });
  const [loading, setLoading] = React.useState(false);
  const [popup, setPopup] = React.useState(null);

  //const columnFilterMappings = x => columns.filter(c => c.valueOptions !== undefined).map(c => {c.field, c.valueOptions});


  React.useEffect(() => {
    let active = true;

    (async () => {
      setLoading(true);

      const filters = filter
        ? filter.items
            .filter(x => x.value)
            .map(x => {
              return `${x.columnField}#${x.operatorValue}#${
                mapStatus && x.columnField === "status" ? TICKET_STATUS_VALUE[x.value] 
                  : mapStatus && x.columnField === "paymentStatus" ? PAYMENT_STATUS_VALUE[x.value]
                  : x.value?.replace("#", "")
              }`;
            })
            .join("|")
        : null;
      const sort = order.map(x => `${x?.field}#${x.sort}`).join("|");
      const limit = `${page * pageSize}#${pageSize}`;
      const response = await loadData(filters, sort, limit);

      if (!active) return;

      if (response !== undefined)
        setData({
          rowCount: response.limit?.total ? response.limit.total : response.length,
          rows: response.result ? response.result : response
        });

      setLoading(false);
    })();

    return () => (active = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRefresh, refresh, filter, order, page, pageSize]);

  const onAction = React.useCallback(
    (a, e) => async () => {
      if (a.onClick) {
        let content = a.onClick({
          action: a,
          item: e?.row,
          popup: {
            title: a.title,
            description: a.description,
            handleClose: result => {
              setPopup(null);
              if (result) setRefresh({});
              //setTimeout(() => setRefresh({}), 300);
            }
          },
          data
        });

        if (content) setPopup(content);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const { userContext } = useUserContext();

  const rowActions = actions
    ?.filter(a => a.type === "row" && userContext.user.permissions.includes(a.permission))
    ?.map((a, i) => ({
      ...a,
      ...actionsColumn,
      field: `field_${i}`,
      headerName: a.label,
      getActions: e => [
        a.isTextButton ? (
          <Button sx={{ borderRadius: 15, width: 160 }} variant="contained" {...a} onClick={onAction(a, e)}>
            {a.buttonText}
          </Button>
        ) : (
          <GridActionsCellItem {...a} onClick={onAction(a, e)} />
        )
      ]
    }));

  const gridActions = actions
    ?.filter(a => a.type === "grid" && userContext.user.permissions.includes(a.permission))
    .map((a, i) => (
      <Button
        {...a}
        variant={i > 0 ? "outlined" : "contained"}
        color={i > 0 ? "primary" : "primary"}
        onClick={onAction(a, null)}
        key={a.label}
        startIcon={a.icon}
        className="GridActionButton"
      >
        {a.label}
      </Button>
    ));

  const orderColumn = useMemo(
    () => ({
      field: "order",
      headerName: "Kolejność",
      sortable: false,
      filterable: false,
      renderCell: params => (
        <Box>
          <ArrowCircleUpIcon
            color="primary"
            onClick={async () => {
              await changeOrder({ id: params.row.id, up: true });
              setRefresh({});
            }}
          />
          <ArrowCircleDownIcon
            color="primary"
            onClick={async () => {
              await changeOrder({ id: params.row.id, up: false });
              setRefresh({});
            }}
          />
        </Box>
      )
    }),
    []
  );

  if (withOrderColumn) {
    columns.unshift(orderColumn);
  }

  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        height: "100%",
        width: "100%"
      }}
      className="DataGrid"
    >
      <HeaderComponent title={title} gridActions={gridActions} />
      <DataGridPro
        initialState={{
          sorting: {
            sortModel: initialSort
          },
          filter: {
            filterModel: initialFilter
          }
        }}
        sx={{
          flexGrow: 1,
          p: 3,
          "& .MuiDataGrid-columnHeaderTitle": {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1,
            fontWeight: "bold"
          },
          "& .MuiDataGrid-renderingZone": {
            maxHeight: "none !important"
          },
          "& .MuiDataGrid-cell": {
            lineHeight: "unset !important",
            maxHeight: "none !important",
            whiteSpace: "normal"
          },
          "& .MuiDataGrid-row": {
            maxHeight: "none !important"
          }
        }}
        components={{ Footer: FooterComponent }}
        hideFooter={hideFooter}
        //componentsProps={{ footer: { gridActions }}}
        columns={columns.concat(rowActions)}
        disableColumnPinning
        rowAlter
        pagination
        paginationMode="server"
        pageSize={pageSize}
        rowsPerPageOptions={[10, 30, 50]}
        onPageSizeChange={s => setPageSize(s)}
        onPageChange={p => setPage(p)}
        sortingMode="server"
        sortModel={order}
        onSortModelChange={s => setOrder(s)}
        filterMode="server"
        onFilterModelChange={f => setFilter(f)}
        loading={loading}
        rowHeight={80}
        headerHeight={95}
        {...data}
        style={{
          backgroundColor: "#ffffff",
          borderRadius: 15,
          padding: 0,
          color: useTheme().palette.common.black,
          fontSize: 16,
          ...style
        }}
      />
      {popup}
    </div>
  );
};
