import { axiosClient } from "Services/api/axios";

export async function getRegisters(filter, sort, limit) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/registers",
    params: { filter: filter, sort: sort, limit: limit }
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
    });

  return resData;
}
