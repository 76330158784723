import React from "react";
import { Controller } from "react-hook-form";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const FormSelectField = (props) => {
  const handleChange = (onChange, value, name) => {
    return onChange;
  };

  return (
    <Grid item xs={props.xs} sm={props.sm}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.value}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => (
          <FormControl
            fullWidth
            variant={props.variant}
            required={props.required}
            error={!!error}
          >
            <InputLabel id={props.label}>{props.label}</InputLabel>
            <Select
              multiple={props.multiple ? true : false}
              name={props.name}
              id={props.name}
              label={props.label}
              value={value}
              onChange={handleChange(onChange, value, name)}
            >
              {props.items === undefined
                ? null
                : props.items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
            </Select>
            {error ? <FormHelperText>{error.message}</FormHelperText> : null}{" "}
          </FormControl>
        )}
        rules={props.rules}
      />
    </Grid>
  );
};

export default FormSelectField;
