import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  paper: {
    maxWidth: 1200,
    marginTop: theme.spacing(4),
    alignItems: "center"
  },
  quickButton: {
    textTransform: "none"
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  hidden: {
    display: "none"
  },
  homeCardBox: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 15,
    border: "1px solid " + theme.palette.grey[300],
    "& .icon": {
      padding: "20px 20px 0 20px"
    },
    "& .content": {
      padding: "25px 20px 20px 20px"
    },
    "& .content .box": {
      border: "1px solid " + theme.palette.grey[300],
      borderRadius: 7,
      width: "100%",
      padding: "0 15px",
      fontWeight: 700,
      lineHeight: 1,
      position: "relative",
      height: 150
    },
    "& .content .box.small": {
      height: 100
    },
    "& .content p": {
      margin: 0,
      fontWeight: 700
    },
    "& .content .big": {
      fontSize: 84
    },
    "& .content .small": {
      fontSize: 22
    },
    "& .content .normal": {
      fontSize: 60
    },
    "& .content .label": {
      position: "absolute",
      fontSize: 14,
      color: "#707070",
      fontWeight: 400,
      backgroundColor: theme.palette.common.white,
      padding: "2px 7px",
      left: 12,
      top: -10,
      width: "auto"
    },
    "& .flexCenter": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    "& .link": {
      padding: "15px 20px",
      borderTop: "1px solid " + theme.palette.grey[300],
      color: theme.palette.primary.main,
      textAlign: "left",
      justifyContent: "left",
      fontSize: "16px"
    },
    "& .link svg": {
      fontSize: 21,
      marginLeft: 5
    },
    "& .link a": {
      textDecoration: "none",
      color: theme.palette.primary.main
    },
    "& .link a:hover": {
      color: "inherit"
    },
    "& .iconBox": {
      width: "90px",
      height: "90px",
      border: "3px solid",
      borderRadius: "50%",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  }
}));

export default useStyles;
