import React from "react";

import { Grid } from "@mui/material";
import { FormPopup } from "Components/General/Popup/FormPopup";
import FormTextField from "Components/General/FormTypes/FormTextField";

import { useForm } from "react-hook-form";
import { saveLanguage, updateLanguage } from "Services/api/languages/languages";
import { IconUploader } from "./components/IconUploader";

export const AddEditLanguage = ({ popup, action, item }) => {
  const [language, setLanguage] = React.useState(item);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);

  const setIcon = icon => {
    setLanguage(language => ({ ...language, icon }));
  };

  const onSubmit = async data => {
    if (language?.icon) {
      const modified = { ...language, ...data };
      item != null ? await updateLanguage(modified) : await saveLanguage(modified);
    } else {
      setSubmitAttempted(true);
      return true;
    }
  };

  const onError = () => {
    if (language?.icon) {
      setSubmitAttempted(false);
    } else {
      setSubmitAttempted(true);
    }
  };

  const form = useForm();

  return (
    <FormPopup popup={popup} form={form} onSubmit={onSubmit} onError={onError}>
      <Grid container direction="column" spacing={2}>
        <FormTextField
          control={form.control}
          name={"description"}
          label={"Nazwa"}
          required={true}
          maxLength={50}
          rules={{ required: "Podaj nazwę" }}
          value={language?.description}
        />
        <FormTextField
          control={form.control}
          name={"name"}
          label={"Oznaczenie"}
          maxLength={2}
          required={true}
          rules={{ required: "Podaj oznaczenie" }}
          value={language?.name}
        />
        <IconUploader icon={language?.icon} setIcon={setIcon} submitAttempted={submitAttempted} />
      </Grid>
    </FormPopup>
  );
};
