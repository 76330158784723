import React from "react";

import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import useStyles from "../style";

const HomeCard = props => {
  const classes = useStyles();

  return (
    <Box className={classes.homeCardBox}>
      <Grid container direction="column">
        {props.icon && (
          <Grid item className="icon flexCenter">
            {props.icon}
          </Grid>
        )}
        <Grid item className="content flexCenter">
          <Box className={"box flexCenter " + props.type}>
            <Grid container direction="column" className="flexCenter">
              {props.content}
            </Grid>
            <Grid container className="label">
              {props.label}
            </Grid>
          </Box>
        </Grid>
        {props.anchor && (
          <Grid item className="link flexCenter">
            <Link
              to={{
                pathname: props.link,
                search: props.queryString
              }}
            >
              {props.anchor} <ArrowForwardIcon />
            </Link>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default HomeCard;
