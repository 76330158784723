import React from "react";
import { useEffect, useState } from "react";
import Meta from "Components/General/Meta/index";
import { Grid, Stack, Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import HomeCard from "./components/HomeCard";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import SubdirectoryArrowLeftIcon from "@mui/icons-material/SubdirectoryArrowLeft";
import { getSummary } from "Services/api/summary/summary";
import { useTheme } from "@mui/material/styles";
import useUserContext from "Context/useUserContext";

import { PasswordAlert } from "./components/PasswordAlert";

const Home = () => {
  const [summary, setSummary] = useState(null);
  const { userContext } = useUserContext();
  const [allow, setAllow] = useState(false);
  const [showPopup, setshowPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSummary();

      if (response !== undefined) setSummary(response);
    };

    if (JSON.stringify(userContext.user.permissions).match(/summary/i)) {
      setAllow(true);
      fetchData();
    }
    if (userContext.changePassword) {
      setshowPopup(true);
    }
  }, []);

  function ShowContent() {
    return (
      <div>
        <Grid container direction="row" spacing={4} style={{ maxWidth: "1030px", padding: "0 0 20px 0" }}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <HomeCard
              icon={
                <Box
                  className="iconBox"
                  style={{
                    borderColor: useTheme().palette.primary.main
                  }}
                >
                  <ConfirmationNumberIcon style={{ color: useTheme().palette.primary.main, fontSize: "45px" }} />
                </Box>
              }
              label="Sprzedane bilety w tym miesiącu"
              link="/sale"
              queryString="?status=Kupiony"
              anchor="Wszystkie sprzedane bilety"
              content={
                <>
                  <p className="big" style={{ color: useTheme().palette.primary.main }}>
                    {summary?.sold}
                  </p>
                  <p title="Wartość sprzedanych biletów" className="small">
                    {summary?.soldAmount} zł
                  </p>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <HomeCard
              icon={
                <Box
                  className="iconBox"
                  style={{
                    borderColor: useTheme().palette.secondary.main
                  }}
                >
                  <SyncAltIcon style={{ color: useTheme().palette.secondary.main, fontSize: "45px" }} />
                </Box>
              }
              label="Wymienione bilety w tym miesiącu"
              link="/sale"
              queryString="?status=Wymiana"
              anchor="Wszystkie wymienione bilety"
              content={
                <>
                  <p className="big" style={{ color: "#f5b900" }}>
                    {summary?.exchanged}
                  </p>
                  <p
                    title="Wartość biletów kupionych w ramach wymiany minus wartość biletów zwróconych w ramach wymiany"
                    className="small"
                  >
                    {summary?.exchangedAmount} zł
                  </p>
                </>
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <HomeCard
              icon={
                <Box className="iconBox" style={{ borderColor: "#e83030" }}>
                  <SubdirectoryArrowLeftIcon style={{ color: "#e83030", fontSize: "45px" }} />
                </Box>
              }
              label="Zwrócone bilety w tym miesiącu"
              link="/sale"
              queryString="?status=Zwrócony"
              anchor="Wszystkie zwrócone bilety"
              content={
                <>
                  <p className="big" style={{ color: "#e83030" }}>
                    {summary?.returned}
                  </p>
                  <p title="Wartość biletów za które dokonano zlecenie automatycznego zwrotu" className="small">
                    {summary?.returnedAmount} zł
                  </p>
                  <p
                    title="Sumaryczna kwota potrącenia"
                    className="small"
                    style={{ color: useTheme().palette.grey[500], marginTop: 3 }}
                  >
                    {summary?.returnedFeeAmount} zł
                  </p>
                </>
              }
            />
          </Grid>
        </Grid>

        <Grid container direction="row" spacing={4} style={{ maxWidth: "1030px", padding: "0 0 20px 0" }}>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <HomeCard
              label="Ilość wszystkich kont"
              content={
                <>
                  <p className="normal" style={{ color: useTheme().palette.primary.main }}>
                    {summary?.accounts}
                  </p>
                </>
              }
              type="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <HomeCard label="Ilość subkont" content={<p className="normal">{summary?.subaccounts}</p>} type="small" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4}>
            <HomeCard
              label="Ilość niesfinalizowanych transakcji"
              content={
                <p className="normal" style={{ color: "#e83030" }}>
                  {summary?.transactions}
                </p>
              }
              type="small"
            />
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <>
      <Grid container direction="row" padding={"0 0 20px 0"}>
        <Grid item xs={12} marginTop="10px" marginBottom="10px">
          <Stack spacing={1} direction="row" justifyContent="flex-start">
            <Meta subTitle={"Strona główna"}></Meta>
            <Typography component="h1" variant="h5">
              {"Strona główna"}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
      {allow ? <ShowContent /> : <></>}

      {showPopup && <PasswordAlert />}
    </>
  );
};

export default Home;
