import React from "react";

import { Box, Card, CardContent, Grid, Paper, Stack } from "@mui/material";
import { FormPopup } from "Components/General/Popup/FormPopup";

import { getLanguages } from "Services/api/translations/languages";
import { saveNews, updateNews, getNewsWithTranslations } from "Services/api/cms/news";

import { useForm } from "react-hook-form";
import { TranslationEditor } from "../TranslationEditor/TranslationEditor";
import FormDateTimePicker from "Components/General/FormTypes/FormDateTimePicker";

export const AddEditNews = ({ popup, action, item }) => {
  const form = useForm();

  const [news, setNews] = React.useState(null);
  const [translations, setTranslations] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    const getNewsData = async () => {
      if (item) {
        const news = await getNewsWithTranslations(item.id);
        setNews(news);
        setTranslations(news.translations);
      } else {
        const languages = await getLanguages();
        const translations = languages.map(lang => {
          return {
            langId: lang.id,
            langDescription: lang.description,
            title: "",
            content: "",
            shortDescription: ""
          };
        });
        setTranslations(translations);
      }
    };
    getNewsData();
  }, [item]);

  const onSubmit = async data => {
    const translationsData = translations.map(translation => ({
      ...translation,
      title: data[`title_${translation.langId}`],
      content: data[`content_${translation.langId}`],
      shortDescription: data[`shortDescription_${translation.langId}`]
    }));

    const modified = {
      id: news?.id,
      publishDate: data?.publishDate,
      expiryDate: data?.expiryDate,
      translations: translationsData
    };

    item != null ? await updateNews(modified) : await saveNews(modified);
  };

  const onError = error => {
    setErrors(error);
  };

  return (
    <FormPopup popup={popup} form={form} onSubmit={onSubmit} onError={onError}>
      <Stack spacing={2}>
        <Paper sx={{ borderRadius: 4, padding: 1 }}>
          <Box>
            <Card variant="contained" sx={{ fontSize: 22 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormDateTimePicker
                      control={form.control}
                      name="publishDate"
                      label="Data publikacji"
                      value={news?.publishDate ?? new Date()}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormDateTimePicker
                      control={form.control}
                      name="expiryDate"
                      label="Data wygaśnięcia"
                      value={news?.expiryDate ?? null}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Paper>
        {translations?.map((translation, index) => (
          <TranslationEditor key={index} translation={translation} form={form} errors={errors} withDescription />
        ))}
      </Stack>
    </FormPopup>
  );
};
