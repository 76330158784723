import { Button, Grid } from "@mui/material";
import useStyles from "./style";

const FormSubmitButton= (props) => {
  //console.log("props", props);

  const classes = useStyles();

  return (
    <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12}>
      <Button
              id="btn-submit"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              >
                {props.children}
      </Button>
    </Grid>
  );
};

export default FormSubmitButton;
