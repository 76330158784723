import React from "react";
import { Controller } from "react-hook-form";

import {
  Grid,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
} from "@mui/material";

const FormCheckbox = (props) => {
  //console.log("checkbox props", props.items.id);
  return ( 
    <Grid item xs={props.xs ? props.xs : 12} sm={props.sm ? props.sm : 12} >
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        defaultValue={props.value === 'true'}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl required={props.required} error={!!error}>
            <FormControlLabel
              required={props.required}
              label={(props.required ? " * " : "") + props.description}
              key={props.id}
              control={
                <Checkbox
                  id={"cb-" + props.name + "-" + props.id}
                  value={value}
                  checked={value}
                  color="primary"
                  onChange={onChange}
                />
              }
            />
            {error ? <FormHelperText>{error.message}</FormHelperText> : null}{" "}
          </FormControl>
        )}
      />
    </Grid>
  );
};

export default FormCheckbox;
