export const PAYMENT_STATUS_TEXT = Object.freeze({
  0: "Nieopłacona",
  1: "Rozpoczęta",
  2: "W trakcie",
  3: "Zakończona",
  4: "Błąd",
  5: "Odrzucona",
  6: "Anulowana"
});

export const TRANSACTION_STATUS_TEXT = Object.freeze({
  0: "Brak",
  1: "Oczekiwanie na płatność",
  2: "Poprawna",
  3: "Odrzucona",
  4: "Anulowana",
  5: "W trakcie",
});

export const TICKET_STATUS_TEXT = Object.freeze({
  0: "Brak",
  1: "Kupiony",
  2: "Zwrócony",
  3: "Wymieniony",
  4: "Wymiana",
  5: "Deklaracja zwrotu"
});

export const TICKET_STATUS_VALUE = Object.freeze({
  "Brak": 0,
  "Kupiony": 1,
  "Zwrócony": 2,
  "Wymieniony": 3,
  "Wymiana": 4,
  "Deklaracja zwrotu": 5
});

export const PAYMENT_STATUS_VALUE = Object.freeze({
  "Nieopłacona": 0,
  "Rozpoczęta": 1,
  "W trakcie": 2,
  "Zakończona": 3, 
  "Błąd": 4,
  "Odrzucona": 5,
  "Anulowana": 6,
});


export const getTicketStatusColor = status => {
  switch (status) {
    case 1:
      return "success";
    case 2:
      return "error";
    case 3:
      return "warning";
    case 4:
      return "info";

    default:
      return "error";
  }
};

export const getTicketStatusText = status => TICKET_STATUS_TEXT[status];

export const getPaymentStatusText = status => PAYMENT_STATUS_TEXT[status];

export const getPaymentStatusColor = status => {  
  switch (status) {
    case 3: // Zakończona
      return "success";
    case 1: // Rozpoczęta
      return "info";
    case 2: // W trakcie
      return "info";

    default:
      return "error";
  }
};

export const getTransactionStatusText = status =>  TRANSACTION_STATUS_TEXT[status]

export const getTransactionStatusColor = status => {  
  switch (status) {
    case 2: // Zakończona
      return "success";
    case 1: // Rozpoczęta
      return "info";
    case 5: // W trakcie
      return "info";

    default:
      return "error";
  }
};
