import { useState } from 'react';

export default function useUserContext() {

  const getUserContext = () => {

    const context = localStorage.getItem("context");
    return context ? JSON.parse(context) : undefined; 
  };

  const [userContext, setUserContext] = useState(getUserContext());

  const saveUserContext = userContext => {

    localStorage.setItem("context", JSON.stringify(userContext));
    setUserContext(userContext);
  };

  const clearUserContext = () => {

    localStorage.clear();
    setUserContext(undefined);
  };


  return {
    setUserContext: saveUserContext,
    clearUserContext,
    userContext
  }
}
