import React from "react";
import { withRouter } from "react-router-dom";

import { DataView } from "Components/General/DataView/DataView";
import { Edit as EditIcon, Add as AddIcon, Clear as ClearIcon } from "@mui/icons-material";

import { getFaq } from "Services/api/cms/faq";
import { AddEditFaq } from "./AddEditFaq";
import { DeleteFaq } from "./DeleteFaq";

const Faq = () => {
  return (
    <DataView
      title="FAQ"
      columns={[
        { field: "id", hide: true },
        { field: "title", headerName: "Tytuł", flex: 1.5, sortable: false, filterable: false }
      ]}
      actions={[
        {
          type: "row",
          permission: "Cms/Faq/Edit",
          label: "Edytuj",
          title: "Edytuj pytanie",
          icon: <EditIcon className="GridEditIcon" />,
          onClick: params => <AddEditFaq {...params} />
        },
        {
          type: "row",
          permission: "Cms/Faq/Delete",
          label: "Usuń",
          title: "Usuń pytanie",
          icon: <ClearIcon className="GridDeleteIcon" />,
          onClick: params => <DeleteFaq {...params} />
        },
        {
          type: "grid",
          permission: "Cms/Faq/Add",
          label: "Dodaj pytanie",
          title: "Dodaj pytanie",
          icon: <AddIcon className="AddIcon" />,
          onClick: params => <AddEditFaq {...params} />
        }
      ]}
      loadData={(filters, sort, limit) => getFaq(filters, sort, limit)}
      hideFooter
    ></DataView>
  );
};

export default withRouter(Faq);
