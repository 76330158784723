import React from "react";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

import FormTextField from "Components/General/FormTypes/FormTextField";
import FormDatePicker from "Components/General/FormTypes/FormDatePicker";
import FormCheckbox from "Components/General/FormTypes/FormCheckbox";

import useStyles from "../style";

const SettingCard = props => {
  const classes = useStyles();

  return (
    <Box>
      <Card variant="contained" sx={{ fontSize: 22 }}>
        {props.header ? <CardHeader title={props.header} className={classes.cardHeader} /> : null}
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5" component="div" className={classes.cardContentName}>
                {props.name}
              </Typography>
              <Typography variant="subtitle1" component="div" className={classes.cardContentDescription}>
                {props.description}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {props.valueType === "text" ? (
                <FormTextField
                  control={props.form.control}
                  name={props.id.toString()}
                  label={props.valueName}
                  required={props.required}
                  rules={{ required: "Pole obowiązkowe" }}
                  value={props.value}
                  type={props.valueType}
                  maxLength={300}
                />
              ) : props.valueType === "number" ? (
                <FormTextField
                  control={props.form.control}
                  name={props.id.toString()}
                  label={props.valueName}
                  required={props.required}
                  value={props.value}
                  type={"text"}
                  maxLength={300}
                  rules={{
                    required: "Pole obowiązkowe",
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Pole może zawierać tylko cyfry"
                    }
                  }}
                />
              ) : props.valueType === "date" ? (
                <FormDatePicker
                  control={props.form.control}
                  name={props.id.toString()}
                  label={props.valueName}
                  required={props.required}
                  rules={{ required: "Pole obowiązkowe" }}
                  value={props.value}
                />
              ) : props.valueType === "boolean" ? (
                <FormCheckbox
                  required={false}
                  control={props.form.control}
                  id={props.id.toString()}
                  description={props.valueName}
                  name={props.id.toString()}
                  value={props.value}
                />
              ) : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SettingCard;
