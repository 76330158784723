import React from "react";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { FileDownload } from "@mui/icons-material";

import { getGridDateOperators } from "@mui/x-data-grid-pro";
import { DataView } from "Components/General/DataView/DataView";
import { getLogs, getLogFile } from "Services/api/logs/logs";


const Logs = () => {
  const downloadFile = logs => {
    getLogFile(logs.item.id).then(response => {
      const url = URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${logs.item.userEmail} ${moment(logs.item.date).format("DDMMYYYY")} ${moment(logs.item.date).format(
          "HHmm"
        )}.txt`
      );
      document.body.appendChild(link);
      link.click();
    });
  };

  return (
    <DataView
      title="LISTA LOGÓW"
      columns={[
        {
          field: "id",
          headerName: "ID",
          flex: 1,
          filterable: false
        },
        {
          field: "date",
          headerName: "Data",
          flex: 2,
          type: "dateTime",
          valueFormatter: params =>
            `${moment(params.value).format("DD.MM.YYYY")} ${moment(params.value).format("HH:mm")}`,
          filterOperators: getGridDateOperators(true).filter(
            operator => operator.value === "onOrAfter" || operator.value === "before"
          )
        },
        { field: "userEmail", headerName: "Nazwa użytkownika", flex: 4.5 }
      ]}
      actions={[
        {
          flex: 1,
          type: "row",
          label: "Pobierz",
          title: "Pobierz",
          permission: "Logs",
          icon: <FileDownload className="GridEditIcon" />,
          onClick: params => downloadFile({ ...params })
        }
      ]}
      loadData={(filter, sort, limit) => getLogs(filter, sort, limit)}
      initialSort={[{ field: "date", sort: "desc" }]}
      mapStatus
      initialFilter={{
        items: [
          {
            columnField: "date",
            operatorValue: "onOrAfter",
            id: 26203,
            value: moment(new Date()).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).format("YYYY-MM-DDTHH:mm")
          }
        ],
        linkOperator: "and"
      }}
    ></DataView>
  );
};

export default withRouter(Logs);
