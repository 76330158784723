import React from "react";

import { Stack } from "@mui/material";
import { FormPopup } from "Components/General/Popup/FormPopup";

import { getLanguages } from "Services/api/translations/languages";
import { saveFaq, updateFaq, getFaqWithTranslations } from "Services/api/cms/faq";

import { useForm } from "react-hook-form";
import { TranslationEditor } from "../TranslationEditor/TranslationEditor";

export const AddEditFaq = ({ popup, action, item }) => {
  const form = useForm();

  const [faq, setFaq] = React.useState(null);
  const [translations, setTranslations] = React.useState([]);
  const [errors, setErrors] = React.useState({});

  React.useEffect(() => {
    const getFaqData = async () => {
      if (item) {
        const faq = await getFaqWithTranslations(item.id);
        setFaq(faq);
        setTranslations(faq.translations);
      } else {
        const languages = await getLanguages();
        const translations = languages.map(lang => {
          return {
            langId: lang.id,
            langDescription: lang.description,
            title: "",
            content: ""
          };
        });
        setTranslations(translations);
      }
    };
    getFaqData();
  }, [item]);

  const onSubmit = async data => {
    const translationsData = translations.map(translation => ({
      ...translation,
      title: data[`title_${translation.langId}`],
      content: data[`content_${translation.langId}`]
    }));

    const modified = {
      id: faq?.id,
      photo: faq?.photo,
      isPublic: !!data?.isPublic,
      translations: translationsData
    };

    item != null ? await updateFaq(modified) : await saveFaq(modified);
  };

  const onError = error => {
    setErrors(error);
  };

  return (
    <FormPopup popup={popup} form={form} onSubmit={onSubmit} onError={onError}>
      <Stack spacing={2}>
        {translations?.map((translation, index) => (
          <TranslationEditor key={index} translation={translation} form={form} errors={errors} />
        ))}
      </Stack>
    </FormPopup>
  );
};
