import React from "react";

import { Grid, Paper, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";

import { FormPopup } from "Components/General/Popup/FormPopup";
import FormTextField from "Components/General/FormTypes/FormTextField";

import { getLanguages } from "Services/api/translations/languages";
import { saveContact } from "Services/api/cms/contact";
import { updateContact } from "Services/api/cms/contact";

import useStyles from "./style";

import { useForm } from "react-hook-form";

export const AddEditContact = ({ popup, action, item }) => {
  const classes = useStyles();

  const [contact, setContact] = React.useState(item);
  const [translations, setTranslations] = React.useState([]);

  React.useEffect(() => {
    (async () =>
      setTranslations(
        item
          ? item.translations
          : (await getLanguages()).map(lang => ({ langId: lang.id, langDescription: lang.description, topic: null }))
      ))();
  }, [item]);

  const onSubmit = async data => {
    let translations = [];
    let email = "";

    for (const [key, value] of Object.entries(data)) {
      if (key === "email") {
        email = value;
      } else {
        translations.push({ langId: key, topic: value });
      }
    }

    let modified = { id: contact?.id, email: email, translations: translations };
    const result = item != null ? await updateContact(modified) : await saveContact(modified);
  };

  const form = useForm();
  const emailPattern = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

  return (
    <FormPopup popup={popup} form={form} onSubmit={onSubmit}>
      <Stack spacing={2}>
        <Paper sx={{ borderRadius: 4, padding: 1 }}>
          <Box>
            <Card variant="contained" sx={{ fontSize: 22 }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormTextField
                      control={form.control}
                      name={"email"}
                      label={"Email"}
                      required={true}
                      maxLength={50}
                      rules={{
                        required: "Podaj email",
                        pattern: { value: emailPattern, message: "Niepoprawny format adresu email" }
                      }}
                      value={contact?.email}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Paper>
        {translations.map(translation => (
          <Paper key={translation.langId} sx={{ borderRadius: 4, padding: 1 }}>
            <Box>
              <Card variant="contained" sx={{ fontSize: 22 }}>
                <CardHeader title={"JĘZYK " + translation.langDescription} className={classes.cardHeader} />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormTextField
                        control={form.control}
                        name={translation.langId.toString()}
                        label={"Opis"}
                        required={true}
                        rules={{ required: "Pole obowiązkowe" }}
                        value={translation.topic}
                        maxLength={50}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Paper>
        ))}
      </Stack>
    </FormPopup>
  );
};
