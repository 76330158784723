import React from "react";
import { withRouter } from "react-router-dom";

import { DataView } from "Components/General/DataView/DataView";
import { Edit as EditIcon, Add as AddIcon, Clear as ClearIcon } from "@mui/icons-material";
import { getRoles } from "Services/api/roles/roles";
import { AddRole } from "./AddRole";
import { EditRole } from "./EditRole";
import { DeleteRole } from "./DeleteRole";

const Roles = () => {
  return (
    <DataView
      title="ROLE I UPRAWNIENIA"
      columns={[
        { field: "id", hide: true },
        { field: "name", headerName: "Rola", flex: 1 },
        { field: "description", headerName: "Opis", flex: 3 },
        { field: "usersCount", headerName: "Liczba pracowników", width: 190 }
      ]}
      actions={[
        {
          type: "row",
          permission: "Roles/Add",
          label: "Edytuj",
          title: "Edycja roli",
          icon: <EditIcon className="GridEditIcon" />,
          onClick: params => form => <EditRole {...params} form={form} />
        },
        {
          type: "row",
          permission: "Roles/Delete",
          label: "Usuń",
          title: "Usunięcie roli",
          icon: <ClearIcon className="GridDeleteIcon" />,
          onClick: params => form => <DeleteRole {...params} form={form} />
        },
        {
          type: "grid",
          permission: "Roles/Edit",
          label: "Dodaj rolę",
          title: "Dodaj rolę",
          icon: <AddIcon className="AddIcon" />,
          onClick: params => form => <AddRole {...params} form={form} />
        }
      ]}
      loadData={(filter, sort, limit) => getRoles(filter, sort, limit)}
    ></DataView>
  );
};

export default withRouter(Roles);
