import React from "react";
import { withRouter } from "react-router-dom";
import { useEffect, useState } from "react";

import { useForm } from "react-hook-form";

import { Button, Stack, Typography, Paper, Grid } from "@mui/material";

import useStyles from "./style";
import { getSettings, editSettings } from "Services/api/settings/settings";
import Meta from "Components/General/Meta/index";
import SettingCard from "./components/SettingCard";

const Settings = () => {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await getSettings();

      if (response !== undefined) setSettings(response);
    };

    fetchData();
  }, []);

  const handleSubmit = data => {
    var settingsList = Object.entries(data).map(([k, v]) => ({ id: k, value: v }));

    editSettings(settingsList);
  };

  const form = useForm();
  const classes = useStyles();

  return settings ? (
    <Stack direction="column" spacing={2}>
      <Stack spacing={1} direction="row" justifyContent="flex-start">
        <Meta subTitle={"USTAWIENIA"}></Meta>
        <Typography component="h1" variant="h5">
          {"USTAWIENIA"}
        </Typography>
      </Stack>

      {settings?.map(item => (
        <Paper key={item.settingGroup} sx={{ borderRadius: 4, padding: 1 }}>
          <Grid container spacing={2}>
            {item.settings?.map(setting => (
              <Grid item xs={6} key={setting.id}>
                <SettingCard
                  id={setting.id}
                  key={setting.id}
                  valueName={setting.valueName}
                  form={form}
                  name={setting.name}
                  header={setting.header}
                  description={setting.description}
                  value={setting.value}
                  valueType={setting.valueType}
                  required={true}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}

      <Stack direction="row" spacing={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={form.handleSubmit(handleSubmit)}
          className={classes.submitButton}
        >
          Zapisz
        </Button>
      </Stack>
    </Stack>
  ) : null;
};

export default withRouter(Settings);
