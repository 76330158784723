import { useHistory } from "react-router-dom";
import { withStyles } from "@mui/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";

import { Link as RouterLink } from "react-router-dom";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5"
  }
})(props => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    /*'&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },*/
  }
}))(MenuItem);

const UserMenu = props => {
  const handleClose = () => {
    props.handleClose();
  };

  const history = useHistory();

  return (
    <>
      <StyledMenu
        id="user-menu"
        anchorEl={props.topAnchor}
        keepMounted
        open={Boolean(props.topAnchor)}
        onClose={handleClose}
      >
        <StyledMenuItem id="mi-user-change-pwd" component={RouterLink} to={"user-change-pwd"}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Zmień hasło" />
        </StyledMenuItem>

        <StyledMenuItem id="mi-user-change-data" component={RouterLink} to={"user-change-data"}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Zmień dane" />
        </StyledMenuItem>

        <StyledMenuItem
          onClick={() => {
            props.signOut();
            history.push("/");
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Wyloguj" />
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
};

export default UserMenu;
