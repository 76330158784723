export const getTransactionTypeName = type => {
  switch (type) {
    case "sale":
      return "Sprzedaż";
    case "return":
      return "Zwrot";
    case "exchange":
      return "Wymiana";

    default:
      return "";
  }
};
