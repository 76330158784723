import { useForm } from "react-hook-form";

import useStyles from "./style";
import Meta from "Components/General/Meta/index";
import { LoginUser } from "Services/api/security/security";
import { setAxiosClientAuthHeaders } from "Services/api/axios";

import FormTextField from "Components/General/FormTypes/FormTextField";
import FormPasswordField from "Components/General/FormTypes/FormPasswordField";

import { Container, Button, Typography, Link, Grid, Checkbox, FormControlLabel } from "@mui/material";

export default function Login({ setUserContext }) {
  const classes = useStyles();

  const { handleSubmit, control } = useForm();

  const onSubmit = async data => {
    let response = await LoginUser(data.email, data.password);
    if (!response.error) {
      await setAxiosClientAuthHeaders(response.data.token);
      setUserContext(response.data);      
    }
  };

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Meta subTitle={"Zaloguj się"}></Meta>
      <Typography className={classes.title} component="h1" variant="h5">
        {"Logowanie"}
      </Typography>
      <div className={classes.loginForm}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className={classes.input}>
            <FormTextField
              control={control}
              label={"Email"}
              required={true}
              name={"email"}
              rules={{ required: "Podaj poprawny email" }}
              key={"email"}
            />
          </div>
          <div className={classes.input}>
            <FormPasswordField
              control={control}
              label={"Hasło"}
              required={true}
              name={"password"}
              rules={{ required: "Podaj hasło" }}
              key={"password"}
            />
          </div>
          <div className={classes.checkbox}>
            <FormControlLabel
              control={<Checkbox id="ch-remember-me" value="remember" color="primary" className="check" />}
              label={"Zapamiętaj mnie"}
            />
          </div>
          <Button
            id="btn-submit"
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {"Zaloguj"}
          </Button>
          <Grid container className="link">
            <Grid item xs>
              <Link href="/security/reset-init" variant="body2">
                {"Zapomniałem/am hasła"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

//export default Login;
