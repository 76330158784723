import { axiosClient } from "Services/api/axios";
import { extractFileName } from "Common/utils/filesUtils";

export async function getLanguages() {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/languages"
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function saveLanguage(data) {
  let [result] = [true];

  await axiosClient({
    method: "POST",
    url: "/languages",
    data: data
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      result = false;
    });

  return result;
}

export async function updateLanguage(data) {
  let [result] = [true];
  await axiosClient({
    method: "PUT",
    url: "/languages/" + data.id,
    data: data
  })
    .then(response => {
      console.log(response.data);
      return response.data;
    })
    .catch(error => {
      console.log(error);
      result = false;
    });
  return result;
}

export async function deleteLanguage(id) {
  await axiosClient({
    method: "DELETE",
    url: "/languages/" + id
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}

export async function uploadTranslation(id, logFile) {
  let [result] = [true];

  await axiosClient({
    method: "POST",
    url: `/languages/${id}/upload`,
    data: logFile,
    headers: { "Content-Type": "multipart/form-data" }
  })
    .then(response => {
      result = response.data;
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      result = false;
    });

  return result;
}

export async function downloadTranslation(id) {
  return await axiosClient({
    method: "POST",
    url: `/languages/${id}/download`,
    responseType: "blob"
  })
    .then(response => {
      console.log(response.data);
      return { data: response.data, fileName: extractFileName(response.headers["content-disposition"]) };
    })
    .catch(error => {
      console.log(error);
    });
}


