import React from "react";
import useStyles from "./style";
import Meta from "Components/General/Meta/index";
import { Container, Typography } from "@mui/material";

const NotFound = () => {
  const classes = useStyles();

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Meta subTitle={"Nie znaleziono strony"}></Meta>
      <Typography className={classes.title} component="h1" variant="h5">
        Nie znaleziono strony o podanym adresie.
      </Typography>
    </Container>
  );
};

export default NotFound;
