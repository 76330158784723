import { axiosClient } from "Services/api/axios";

export async function getContacts(filters, sort, limit) {
    let [resData] = [undefined];

    await axiosClient({
        method: "GET",
        url: "/cms/contact"
    })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

    return resData;
}


export async function saveContact(data) {

    let [result] = [true];

    await axiosClient({
        method: "POST",
        url: "/cms/contact",
        data: data
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            result = false;
        });

    return result;
}

export async function updateContact(data) {
    let [result] = [true];
    await axiosClient({
        method: "PUT",
        url: "/cms/contact/" + data.id,
        data: data
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            result = false;
        });
    return result;

}


export async function deleteContact(id) {
    await axiosClient({
        method: "DELETE",
        url: "/cms/contact/" + id,
    })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}