import React from "react";

import useUserContext from "Context/useUserContext";
import { useHistory } from "react-router-dom";

import { QuestionPopup } from "Components/General/Popup/QuestionPopup";
import { deleteUser } from "Services/api/users/users";

export const DeleteUser = ({ popup, action, item, clearUserContext }) => {
  const history = useHistory();
  const { userContext } = useUserContext();

  const onSubmit = async () => {
    const result = await deleteUser(item.id);

    if (result && userContext.user.email === item.email) {
      history.push("/");
      clearUserContext();
    }
  };

  return (
    <QuestionPopup popup={popup} onSubmit={onSubmit}>
      {`Czy na pewno chcesz usunąć dane użytkownika ${item?.firstName} ${item?.lastName}?`}
    </QuestionPopup>
  );
};
