import React from "react";
import { Controller } from "react-hook-form";

import {
  Grid,
  Box,
  Chip,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  FormHelperText,
} from "@mui/material";

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FormMultiSelectField = (props) => {
  const handleChange = (onChange, value, name) => {
    return onChange;
  };

  return (
    <Grid item xs={props.xs} sm={props.sm}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={props.value}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => (
          <FormControl
            fullWidth
            variant={props.variant}
            required={props.required}
            error={!!error}
          >
            <InputLabel id={props.label}>{props.label}</InputLabel>
            <Select
              multiple={props.multiple ? true : false}
              name={props.name}
              id={props.name}
              label={props.label}
              value={value}
              onChange={handleChange(onChange, value, name)}
              renderValue={(selected) => ( 
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={props.items.find(item => item.id === value)?.name} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {props.items === undefined
                ? null
                : props.items.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      <Checkbox checked={value.indexOf(item.id) > -1} />
                      <ListItemText primary={item.name} />
                    </MenuItem>
                  ))}
            </Select>
            {error ? <FormHelperText>{error.message}</FormHelperText> : null}{" "}
          </FormControl>
        )}
        rules={props.rules}
      />
    </Grid>
  );
};

export default FormMultiSelectField;
