import React from "react";

import {sendAlert} from "Services/api/unfinishedTransactions/unfinishedTransactions"

import { QuestionPopup } from "Components/General/Popup/QuestionPopup";

export const SendAlert = ({ popup, action, item }) => {
  const onSubmit = async () => {
    await sendAlert(item.id, "unfinishedTransactions" );
  };

  return (
    <QuestionPopup popup={popup} onSubmit={onSubmit}>
      {`Czy na pewno chcesz wysłać alert do użytkownika ${item?.userEmail || ""}?`}
    </QuestionPopup>
  );
};
