import React from "react";
import { MessagePopup } from "Components/General/Popup/MessagePopup";
import { Grid, Typography } from "@mui/material";

import { DataCard } from "Components/Pages/Records/components/DataCard";
import { DataRow } from "Components/Pages/Records/components/DataRow";

export const ResponsePopup = ({ popup, onSubmit, data }) => {
  return (
    <MessagePopup popup={popup} onSubmit={onSubmit} fullWidth>
      <Grid justifyContent="center" container padding={2} spacing={4}>
        <Grid item xs={12}>
          <DataCard title="Podsumowanie">
            <DataRow title="Zaimportowano" data={data?.imported} />
            <DataRow title="Pominięto" data={data?.skipped} />
            <DataRow
              title="Brakujące tłumaczenia"
              customContent={
                <>
                  {data?.missing?.map(text => (
                    <Typography>{text}</Typography>
                  ))}
                </>
              }
            />
          </DataCard>
        </Grid>
      </Grid>
    </MessagePopup>
  );
};
