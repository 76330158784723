import { Box, Divider, Grid, Typography } from "@mui/material";
import React from "react";

export const DataRow = ({ title, data, noDivider, customContent, smallDesc, fontSize }) => {
  return (
    <>
      <Grid space={2} container>
        <Grid item xs={smallDesc ? 3 : 6}>
          <Typography style={{ fontWeight: "bold", fontSize: fontSize }} paddingY={1} paddingX={2}>
            {title}
          </Typography>
        </Grid>
        <Grid item xs={smallDesc ? 9 : 6} alignItems="center" display="flex">
          {customContent ? (
            <Box paddingY={1} paddingX={2}>
              {customContent}
            </Box>
          ) : (
            <Typography component="div" paddingY={1} paddingX={2} style={{ width: "95%", wordWrap: "break-word", fontSize: fontSize }}>
              {data ?? "-"}
            </Typography>
          )}
        </Grid>
      </Grid>
      {!noDivider && <Divider />}
    </>
  );
};
