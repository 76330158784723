import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "50vh"
  },
  main: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  footer: {
    padding: "10px 20px",
    marginTop: "auto",
    textAlign: "center",
    backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[500],
    "& p": {
      fontSize: 12,
      color: "#8d8d8d"
    }
  }
}));

export default useStyles;
