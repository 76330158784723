import React from "react";

import { Box, Grid, Typography } from "@mui/material";
import { FormPopup } from "Components/General/Popup/FormPopup";

import { useForm } from "react-hook-form";
import { uploadTranslation } from "Services/api/languages/languages";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { ResponsePopup } from "./components/ResponsePopup";

export const UploadLanguage = ({ popup, action, item }) => {
  const [file, setFile] = React.useState(null);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [isFileSent, setIsFileSent] = React.useState(false);
  const [uploadResponse, setUploadResponse] = React.useState({});

  const onSubmit = async data => {
    if (file) {
      const response = await uploadTranslation(item.id, file);
      setUploadResponse(response);
      setIsFileSent(true);
      return true;
    } else {
      setSubmitAttempted(true);
      return true;
    }
  };

  const onError = () => {
    if (file) {
      setSubmitAttempted(false);
    } else {
      setSubmitAttempted(true);
    }
  };

  const form = useForm();

  const onChange = async e => {
    const formData = new FormData();
    formData.append("logFile", e.target.files[0]);

    setFile(formData);
  };

  return isFileSent ? (
    <ResponsePopup popup={popup} data={uploadResponse} />
  ) : (
    <FormPopup popup={popup} form={form} onSubmit={onSubmit} onError={onError}>
      <Grid container direction="column" spacing={2}>
        <>
          <Grid
            marginTop={2}
            marginLeft="8px"
            border={1}
            borderColor={submitAttempted ? "error.main" : "rgba(0,0,0,0.23)"}
            borderRadius="4px"
            spacing={4}
            item
            container
            alignItems="center"
            style={{ paddingTop: 0 }}
          >
            <Grid item style={{ paddingTop: 0, paddingLeft: 0 }}>
              <label htmlFor="upload-photo" style={{ cursor: "pointer", marginBottom: 0 }}>
                <input
                  style={{ display: "none" }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                  onChange={onChange}
                  accept=".csv"
                />

                <Box
                  borderRadius="8px"
                  border={1}
                  padding={2}
                  marginY={2}
                  borderColor="primary.main"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <InsertDriveFileIcon color="primary" />
                  <Typography marginLeft={2} color="primary">
                    Dodaj plik
                  </Typography>
                </Box>
              </label>
            </Grid>
            {file && (
              <Grid item style={{ paddingTop: 0 }} marginRight={4}>
                <Typography>{file.get("logFile").name}</Typography>{" "}
              </Grid>
            )}
          </Grid>
          {submitAttempted && (
            <Grid item>
              <Typography marginLeft="14px" fontSize="14px" color="error.main">
                Dodaj plik
              </Typography>
            </Grid>
          )}
        </>
      </Grid>
    </FormPopup>
  );
};
