import Helmet from "react-helmet";

const Meta = meta => {
  console.log("meta", meta);
  return (
    <Helmet>
      <title>Koleje mazowieckie - {meta.subTitle || ""}</title>
    </Helmet>
  );
};

export default Meta;
