import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useHistory } from "react-router-dom";

import useStyles from "./style";
import Meta from "Components/General/Meta/index";
import { ResetPasswordConfirm } from "Services/api/security/security";
import FormPasswordField from "Components/General/FormTypes/FormPasswordField";

import { Avatar, Container, Button, Typography, Grid } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

export const PasswordResetProceed = props => {
  const classes = useStyles();
  let location = useLocation();
  const queryString = require("query-string");

  let history = useHistory();
  const [queryParameters] = useState(queryString.parse(location.search));

  const { handleSubmit, control, getValues } = useForm();

  const onSubmit = async data => {
    const result = await ResetPasswordConfirm(
      data.password1,
      data.password2,
      queryParameters.userId,
      queryParameters.code
    );
    if (result !== undefined) {
      history.push("/");
    }
  };

  const passwordValidityRegexp = /^[A-Za-z\d@$!%*#?&]{8,}$/;
  const passwordValidityMessage = "Hasło musi mieć co najmniej 8 znaków - cyfr, liter lub znaków specjalnych @$!%*#?&";

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Meta subTitle={"Zmień hasło"}></Meta>

      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography className={classes.title} component="h1" variant="h5">
        {"Zmiana hasła"}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container direction="column" spacing={2}>
          <FormPasswordField
            control={control}
            label={"Hasło"}
            required={true}
            name={"password1"}
            rules={{
              required: "Podaj hasło",
              pattern: {
                value: passwordValidityRegexp,
                message: passwordValidityMessage
              }
            }}
            key={"password1"}
          />
          <FormPasswordField
            control={control}
            label={"Powtórz hasło"}
            required={true}
            name={"password2"}
            rules={{
              required: "Podaj powtórnie hasło",
              pattern: {
                value: passwordValidityRegexp,
                message: passwordValidityMessage
              },
              validate: value => value === getValues("password1") || "Hasła muszą być takie same"
            }}
            key={"password2"}
          />
        </Grid>
        <Button id="btn-submit" type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
          {"Zmień"}
        </Button>
      </form>
    </Container>
  );
};
