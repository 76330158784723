import axios from "axios";
export const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BOL_API_URL,
  headers: {
    "Content-Type": "application/json",
    "X-ApiKey": "F6F5CCEA_EB69_4A0A_B469_49D51B989CD1.web",
    //...(authToken !== null) && { "Authorization": `Bearer ${authToken}` },
  },
});

// export const axiosClient = () => {
//   const context = localStorage.getItem("context");
//   const authToken = JSON.parse(context)?.token;

//   //const classes = useStyles();

//   let api = axios.create({
//     baseURL: process.env.REACT_APP_BOL_API_URL,
//     headers: {
//       "Content-Type": "application/json",
//       "X-ApiKey": "F6F5CCEA_EB69_4A0A_B469_49D51B989CD1.web",
//       ...(authToken !== null) && { "Authorization": `Bearer ${authToken}` },
//     },
//   });

//   return api;
// };

export const setAxiosClientAuthHeaders = async authToken => {
  authToken?.length > 0
    ? (axiosClient.defaults.headers.common["Authorization"] = "Bearer " + authToken)
    : delete axiosClient.defaults.headers.common["Authorization"];
}

export const setAxiosClientInterceptor = (addError, clearUserContext, history) => {
  axiosClient.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    //addError('sukces', 0);
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    const message = error.response?.data?.messages?.find(o => true) || error.response?.statusText || error.message;
    const status = error.response?.status ?? 0;

    console.log("request error:" + message);
    addError(message, status);
    if (status === 401 || status === 403) {
      history.push("/");
      clearUserContext();
    }

    return Promise.reject(error);
  });
};

