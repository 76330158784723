import React from "react";
import useStyles from "./style";

import FormTextField from "Components/General/FormTypes/FormTextField";
import FormSubmitButton from "Components/General/FormTypes/FormSubmitButton";

import { updateUser, getUser } from "Services/api/users/users";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Grid, Container, Typography } from "@mui/material";

export const ChangeProfile = props => {
  let history = useHistory();
  const [user, setUser] = React.useState({ ...props.user, phone: "" });

  const { handleSubmit, setValue, control } = useForm();

  React.useEffect(() => {
    (async () => {
      const response = await getUser(props.user.id);
      setUser(response);

      setValue("email", response.email);
      setValue("firstName", response.firstName);
      setValue("lastName", response.lastName);
      setValue("phone", response.phone);
    })();
  }, [props.user]);

  const onSubmit = async data => {
    const modified = { ...user, ...data };
    const response = await updateUser(modified);
    if (!response?.error) {
      history.push("/");
      props.clearUserContext(data);
    }
  };

  const classes = useStyles();

  return (
    <Container className={classes.paper} component="main" maxWidth="md">
      <Typography className={classes.title} component="h1" variant="h5">
        {"Zmiana danych pracownika"}
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <FormTextField
            control={control}
            name={"email"}
            label={"Email"}
            required={true}
            disabled
            value={user?.email}
          />
          <FormTextField
            control={control}
            name={"firstName"}
            label={"Imię"}
            maxLength={50}
            required={true}
            rules={{ required: "Podaj imię" }}
            value={user?.firstName}
          />
          <FormTextField
            control={control}
            name={"lastName"}
            label={"Nazwisko"}
            maxLength={50}
            required={true}
            rules={{ required: "Podaj nazwisko" }}
            value={user?.lastName}
          />
          <FormTextField control={control} name={"phone"} label={"Telefon"} maxLength={50} value={user?.phone} />
          <FormSubmitButton>{"Zmień dane"}</FormSubmitButton>
        </Grid>
      </form>
    </Container>
  );
};
