import React from "react";
import {blockAccount} from "Services/api/unfinishedTransactions/unfinishedTransactions"
import { QuestionPopup } from "Components/General/Popup/QuestionPopup";

export const BlockAccount = ({ popup, action, item }) => {
  const onSubmit = async () => {
    await blockAccount(item.id);
  };

  return (
    <QuestionPopup popup={popup} onSubmit={onSubmit}>
      {`Czy na pewno chcesz zablokować konto użytkownika ${item?.userEmail || ""}? Operacji nie można cofnąć.`}
    </QuestionPopup>
  );
};
