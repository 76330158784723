import { axiosClient } from "Services/api/axios";

export async function getUnfinishedTransactions(filter, sort, limit, period) {
  let [resData] = [undefined];

  await axiosClient({
    method: "GET",
    url: "/unfinished-transactions",
    params: { filter: filter, sort: sort, limit: limit, dateFrom: period?.dateFrom, dateTo: period?.dateTo  }
  })
    .then(response => {
      console.log(response.data);
      resData = response.data;
    })
    .catch(error => {
      console.log(error);
      //resError = error;
    });

  return resData;
}

export async function sendAlert(id, type) {
  await axiosClient({
    method: "POST",
    url: "/unfinished-transactions/" + id + "/notify",
    data: { type }
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}

export async function blockAccount(id) {
  await axiosClient({
    method: "PATCH",
    url: "/users/" + id + "/block",    
  })
    .then(response => {
      console.log(response.data);
    })
    .catch(error => {
      console.log(error);
      return error;
    });
}
