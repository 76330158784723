import { axiosClient } from "Services/api/axios";

export async function getUsers(filter, sort, limit) { 
    let [resData] = [undefined]; 

    await axiosClient({
        method: "GET",
        url: "/users",
        params: { filter: filter, sort: sort, limit: limit }
        })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
            //resError = error;
        });

        return resData;
}

export async function getUser(id) {
    let [resData] = [undefined]; 

    await axiosClient({
        method: "GET",
        url: "/users/" + id + "/backend",        
        })
        .then((response) => {
            console.log(response.data);
            resData = response.data;
        })
        .catch((error) => {
            console.log(error);
        });
        
    return resData;
}


export async function saveUser(data) {

    let [result] = [true]; 

    await axiosClient({
        method: "POST",
        url: "/users/backend",
        data: data
        })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            result = false;
        });

    return result;
}

export async function updateUser(data) {
    let [result] = [true]; 
    await axiosClient({
        method: "PUT",
        url: "/users/" + data.id,
        data: data
        })
        .then((response) => {
            console.log(response.data);
            return response.data;
        })
        .catch((error) => {
            console.log(error);
            result = false;
        });
        return result;

}


export async function deleteUser(id) {
    await axiosClient({
        method: "DELETE",
        url: "/users/" + id,        
        })
        .then((response) => {
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
            return error;
        });
}